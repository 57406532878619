export const Columns = [
  { field: "code", headerName: "Mã sản phẩm", width: 200 },

  { field: "product_name", headerName: "Tên sản phẩm", width: 300 },
  { field: "buy_count", headerName: "Số lượng mua", width: 300 },

  {
    field: "product_images",
    headerName: "Hình ảnh",
    width: 300,
    renderCell: (params) => (
      <div>
        {params?.value[0]?.path ? (
          <img
            src={params.value[0].path}
            alt="Hình ảnh"
            style={{ width: "100px", height: "auto", objectFit: "cover" }}
          />
        ) : (
          "No Images"
        )}
      </div>
    ),
  },
  {
    field: "total_price",
    headerName: "Tổng giá trị sản phẩm",
    width: 300,
    renderCell: (params) => (
      <span>{parseInt(params.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "total_profit_final_for_seller",
    headerName: "Tổng lợi nhuận cho seller",
    width: 300,
    renderCell: (params) => (
      <span>{parseInt(params.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "total_profit_for_pdp",
    headerName: "Tổng lợi nhuận cho nhà cung cấp",
    width: 300,
    renderCell: (params) => (
      <span>{parseInt(params.value).toLocaleString("en-US")}</span>
    ),
  },

  {
    field: "total_profit_for_admin",
    headerName: "Tổng lợi nhuận cho admin",
    width: 300,
    renderCell: (params) => (
      <span>{parseInt(params.value).toLocaleString("en-US")}</span>
    ),
  },
];
