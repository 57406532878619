import { ResponsiveLine } from "@nivo/line";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";
import { useEffect, useState } from "react";

const LineChart = ({
  isCustomLineColors = false,
  isDashboard = false,
  stateDoanhthuADMIN,
  minAD,
  maxAD,
  name,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [stateDataMoc, setstateDataMoc] = useState([]);

  const handleMockdata = () => {
    const transformedData = stateDoanhthuADMIN.map((item) => {
      return {
        ...item,
        x: (item?._id).split("-")[2],
        y: item?.total_money_affiliate,
      };
    });
    console.log(">>>>>>> ", JSON.stringify(transformedData, null, 2));
    setstateDataMoc(transformedData);
  };
  const mockLineData = [
    {
      id: name,
      color: tokens("dark").greenAccent[500],
      data: stateDataMoc,
    },
  ];
  useEffect(() => {
    if (stateDoanhthuADMIN.length !== 0) {
      handleMockdata();
    }
  }, [stateDoanhthuADMIN]);

  return (
    <>
      {stateDoanhthuADMIN.length !== 0 ? (
        <ResponsiveLine
          data={mockLineData}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: colors.greenAccent[100],
                },
              },
              legend: {
                text: {
                  fill: colors.greenAccent[100],
                  fontSize: 20,
                },
              },
              ticks: {
                line: {
                  stroke: colors.greenAccent[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.greenAccent[100],
                  fontSize: 20,
                },
              },
            },
            legends: {
              text: {
                fill: colors.greenAccent[100],
              },
            },
            tooltip: {
              container: {
                color: colors.redAccent[500],
              },
            },
          }}
          colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
          margin={{ top: 50, right: 110, bottom: 50, left: 120 }}
          xScale={{
            type: "point",
            min: "auto", // Có thể sử dụng 'auto' hoặc giá trị tối thiểu cụ thể
            max: "auto", // Có thể sử dụng 'auto' hoặc giá trị tối đa cụ thể
          }}
          yScale={{
            type: "linear",
            min: minAD, // Có thể sử dụng 'auto' hoặc giá trị tối thiểu cụ thể
            max: maxAD, // Có thể sử dụng 'auto' hoặc giá trị tối đa cụ thể
          }}
          yFormat=" >-.2f"
          curve="catmullRom"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : "transportation", // added
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickValues: 5, // added
            tickSize: 3,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : "count", // added
            legendOffset: -40,
            legendPosition: "middle",
            format: (value) => `${parseInt(value).toLocaleString("en-us")}`,
          }}
          enableGridX={false}
          enableGridY={false}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          tooltip={({ point }) => (
            <div
              style={{
                background: "white",
                padding: "5px",
                border: `1px solid black`,
              }}
            >
              <strong
                style={{
                  color: "black",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Typography
                  // color={"#4CCEAC"}
                  fontSize={"16px"}
                  fontWeight={700}
                >
                  {" "}
                  Ngày: {point.data.xFormatted}
                </Typography>

                <Typography
                  color={"#4CCEAC"}
                  fontSize={"16px"}
                  fontWeight={700}
                >
                  {" "}
                  Số tiền:{" "}
                  {parseInt(point.data.yFormatted).toLocaleString("en-us")} VNĐ
                </Typography>
              </strong>
            </div>
          )}
        />
      ) : (
        " "
      )}
    </>
  );
};

export default LineChart;
