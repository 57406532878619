import { Avatar, Box, Button, Popover, Typography } from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import PolicyIcon from "@mui/icons-material/Policy";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import {
  CaseDatetimeToYYYYMMDDHHMMSS,
  CaseDatetimeToYYYYMMDD,
} from "../../../components/function";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Columns = () => {
  let nav = useNavigate();
  const [anchorEls, setAnchorEls] = useState({});

  const handleClick = (event, rowIndex) => {
    setAnchorEls({
      ...anchorEls,
      [rowIndex]: event.currentTarget,
    });
  };

  const handleClose = (rowIndex) => {
    setAnchorEls({
      ...anchorEls,
      [rowIndex]: null,
    });
  };

  return [
    {
      field: "name",
      headerName: "TÊN TÀI KHOẢN",
      width: 200,
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "1.2rem",
            color: "#9292ff",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => {
            nav(`/tai-khoan-tham-gia-seller/${params.row.id}`, {
              state: { name: params?.value, avatar: params.row.avatar },
            });
          }}
        >
          {params?.value}
        </Typography>
      ),
    },

    {
      field: "phone",
      headerName: "SỐ ĐIỆN THOẠI",
      width: 200,
    },
    {
      field: "email",
      headerName: "EMAIL",
      width: 200,
    },
    {
      field: "avatar",
      headerName: "ẢNH ĐẠI DIỆN",
      width: 200,
      renderCell: (params) => (
        <div>
          {params?.value ? (
            <Avatar src={params?.value} sx={{ width: 70, height: 70 }}></Avatar>
          ) : (
            "Chưa có ảnh"
          )}
        </div>
      ),
    },
    {
      field: "verify",
      headerName: "XÁC THỰC",
      width: 200,
      renderCell: (params) => (
        <>
          {params.value ===
          parseInt(process.env.REACT_APP_ACCOUNT_UNVERIFIED) ? (
            <Typography
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              variant="h5"
              p={1}
              bgcolor={"GrayText"}
              fontWeight={"600"}
            >
              <Box
                justifyContent={"space-between"}
                display={"flex"}
                width={"60%"}
              >
                <PolicyIcon></PolicyIcon>{" "}
                <Typography fontWeight={"600"} variant="h5">
                  {" "}
                  Chưa duyệt
                </Typography>
              </Box>
            </Typography>
          ) : params.value ===
            parseInt(process.env.REACT_APP_ACCOUNT_VERIFIED) ? (
            <Typography
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              variant="h5"
              p={1}
              bgcolor={"green"}
              fontWeight={"600"}
            >
              <Box
                justifyContent={"space-between"}
                display={"flex"}
                width={"60%"}
              >
                <SecurityIcon></SecurityIcon>{" "}
                <Typography fontWeight={"600"} variant="h5">
                  {" "}
                  Đã duyệt
                </Typography>
              </Box>
            </Typography>
          ) : (
            <Typography
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              variant="h5"
              p={1}
              bgcolor={"red"}
              fontWeight={"600"}
            >
              <Box
                justifyContent={"space-between"}
                display={"flex"}
                width={"60%"}
              >
                <RemoveModeratorIcon></RemoveModeratorIcon>{" "}
                <Typography fontWeight={"600"} variant="h5">
                  {" "}
                  Ngưng hoạt động
                </Typography>
              </Box>
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "created_at",
      headerName: "Ngày khởi tạo",
      width: 200,
      renderCell: (params) => (
        <div>{CaseDatetimeToYYYYMMDDHHMMSS(params?.value)}</div>
      ),
    },
    {
      field: "upgrade_info",
      headerName: "Tài khoản nâng cấp",
      width: 200,
      renderCell: (params) => {
        const rowIndex = params.id; // id của hàng hiện tại
        const open = Boolean(anchorEls[rowIndex]);
        const id = open ? `simple-popover-${rowIndex}` : undefined;

        return (
          <>
            {params?.value && params?.value.length > 0 ? (
              params?.value[0]?.is_sent_payment ? (
                <>
                  <div>
                    <Button
                      aria-describedby={id}
                      variant="contained"
                      onClick={(event) => handleClick(event, rowIndex)}
                    >
                      Xem chi tiết
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEls[rowIndex]}
                      onClose={() => handleClose(rowIndex)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        Mã đơn nâng cấp : {params?.value[0]?.code_upgrade}
                      </Typography>
                      <Typography sx={{ p: 2 }}>
                        Hết hạn vào ngày :{" "}
                        {CaseDatetimeToYYYYMMDD(params?.value[0]?.expiry_date)}
                      </Typography>
                      <Typography sx={{ p: 2 }}>
                        Gói nâng cấp : {params.row.package_info[0]?.name}
                      </Typography>
                    </Popover>
                  </div>
                  <br></br>
                </>
              ) : (
                <>
                  <div>
                    <Button
                      aria-describedby={id}
                      variant="outlined"
                      sx={{
                        bgcolor: "orangered",
                        fontWeight: 600,
                        color: "white",
                      }}
                      onClick={(event) => handleClick(event, rowIndex)}
                    >
                      Đã gửi yêu cầu
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEls[rowIndex]}
                      onClose={() => handleClose(rowIndex)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        Mã đơn nâng cấp : {params?.value[0]?.code_upgrade}
                      </Typography>
                      <Typography sx={{ p: 2 }}>
                        Thời điểm gửi yêu cầu :{" "}
                        {CaseDatetimeToYYYYMMDD(params?.value[0]?.created_at)}
                      </Typography>
                      <Typography sx={{ p: 2 }}>
                        Gói nâng cấp : {params.row.package_info[0]?.name}
                      </Typography>
                    </Popover>
                  </div>
                </>
              )
            ) : (
              "Chưa nâng cấp"
            )}
          </>
        );
      },
    },
    {
      field: "bank_info",
      headerName: "Tài khoản giao dịch",
      width: 600,
      renderCell: (params) => (
        <>
          {params?.value ? (
            <div>
              Tên tài khoản: {params?.value?.account_number} - Chủ tài khoản:{" "}
              {params?.value?.account_name} - Tên ngân hàng:{" "}
              {params?.value?.bank_name}
            </div>
          ) : (
            "Chưa cập nhật thông tin"
          )}
        </>
      ),
    },

    // {
    //   field: "package_info",
    //   headerName: "Tài khoản nâng cấp",
    //   width: 600,
    //   renderCell: (params) => (
    //     <>
    //       {params?.value ? (
    //         <>
    //           <div>
    //             <Button
    //               aria-describedby={id}
    //               variant="contained"
    //               onClick={handleClick}
    //             >
    //               {params?.value?.name}
    //             </Button>
    //             <Popover
    //               id={id}
    //               open={open}
    //               anchorEl={anchorEl}
    //               onClose={handleClose}
    //               anchorOrigin={{
    //                 vertical: "bottom",
    //                 horizontal: "left",
    //               }}
    //             >
    //               <Typography sx={{ p: 2 }}>
    //                 Mã đơn nâng cấp : {params?.value?.code_upgrade}
    //               </Typography>
    //               <Typography sx={{ p: 2 }}>
    //                 Hết hạn vào ngày :{" "}
    //                 {CaseDatetimeToYYYYMMDD(params?.value?.expiry_date)}
    //               </Typography>
    //             </Popover>
    //           </div>
    //           <br></br>
    //         </>
    //       ) : (
    //         "Chưa nâng cấp"
    //       )}
    //     </>
    //   ),
    // },
  ];
};

export const notification_verify = {
  title: "Thao tác thành công",
  message: "Thành công duyệt tài khoản !",
  type: "success",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_unverify = {
  title: "Thao tác thành công",
  message: "Thành công huỷ duyệt tài khoản !",
  type: "default",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_ban = {
  title: "Thao tác thành công!",
  message: "Thành công ngừng hoạt động tài khoản !",
  type: "danger",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};

export const Export_excel_columns_VN = [
  { header: "SỐ THỨ TỰ", key: "STT", width: 20 },
  { header: "SỐ ĐIỆN THOẠI", key: "phone", width: 40 },
  { header: "TÊN KHÁCH HÀNG", key: "name", width: 40 },
  { header: "EMAIL", key: "email", width: 40 },
  { header: "XÁC THỰC", key: "verify", width: 40 },
  { header: "NGÀY KHỞI TẠO", key: "created_at", width: 40 },
];
export const Export_excel_columns_KR = [
  { header: "순서 번호", key: "STT", width: 20 },
  { header: "전화번호", key: "phone", width: 40 },
  { header: "고객 이름", key: "name", width: 40 },
  { header: "이메일", key: "email", width: 40 },
  { header: "인증", key: "verify", width: 40 },
  { header: "생성 날짜", key: "created_at", width: 40 },
];
