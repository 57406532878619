import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import {
  GET_ALL_ACCOUNT_BY_ROLE,
  GET_ALL_DATA_ACCOUNT_BY_CREATE_AT,
  VERIFY_ACCOUNT,
} from "../../apis/handleAccount";
import { GridToolbar } from "@mui/x-data-grid";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import LoginIcon from "@mui/icons-material/Login";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import { DataRoles } from "../../data/mockData";
import {
  Columns,
  notification_verify,
  notification_unverify,
  notification_ban,
  Export_excel_columns_KR,
  Export_excel_columns_VN,
} from "./components/components";
import { Store } from "react-notifications-component";
import { CustomToolbar, HandleExportToExcel } from "../../components/function";
import { SearchComponent } from "../../components/components";
import DatePicker from "react-date-picker";
import { GET_ALL_DATA_BY_CREATE_AT } from "../../apis/handleDataCenter";
const AccountManage = ({ data }) => {
  const [stateAccount, setStateAccount] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [stateRole, setStateRole] = useState(process.env.REACT_APP_ROLE_SELLER);
  const [statePage, setStatePage] = useState(0);
  const [stateLimit, setStateLimit] = useState(20);
  const [stateTotalAccount, setStateTotalAccount] = useState(0);
  const [stateSortDate, setStateSortDate] = useState("latest");
  const [stateSort, setStateSort] = useState("asc");
  const [stateDate, setStateDate] = useState("latest");
  const [stateListExcel, setStateListExcel] = useState([]);
  const [currentDateF, setCurrentDateF] = useState(new Date());
  const [currentDateT, setCurrentDateT] = useState(new Date());
  const [stateName, setStateName] = useState("");
  const [Isloading, setIsloading] = useState({
    loading_verify: false,
    loading_unverify: false,
    loading_ban: false,
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const DATA_GET_ALL_DATA_FOR_EXCEL = useQuery({
    queryKey: ["GET_ALL_DATA_EXCEL", currentDateF, currentDateT, stateRole],
    queryFn: async () => {
      let dateF =
        currentDateF.getFullYear() +
        "-" +
        String(currentDateF.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(currentDateF.getDate()).padStart(2, "0");

      let dateT =
        currentDateT.getFullYear() +
        "-" +
        String(currentDateT.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(currentDateT.getDate()).padStart(2, "0");

      const res = await GET_ALL_DATA_ACCOUNT_BY_CREATE_AT({
        roles: parseInt(stateRole),
        date: stateDate,
        sort: stateSort,
        startTime: dateF,
        endTime: dateT,
      });
      if (res?.data?.data?.accounts) {
        // setStatePage(res?.data?.data?.page);

        setStateListExcel(res?.data?.data?.accounts);
      }

      return res;
    },
  });
  const DATA_ALL_USER = useQuery({
    queryKey: ["GET_ALL_USER_ACCOUNT", stateRole, statePage, stateName],
    queryFn: async () => {
      const result = await GET_ALL_ACCOUNT_BY_ROLE({
        page: statePage + 1,
        limit: stateLimit,
        date: stateSortDate,
        roles: stateRole,
        sort: stateSort,
        name: stateName,
      });
      if (result?.data?.data) {
        let map = result?.data?.data?.accounts.map((obj, index) => ({
          ...obj,
          id: obj._id,
        }));

        setStateAccount(map);

        setStateTotalAccount(result?.data?.data?.total_account);
      }
      return result;
    },
  });

  const onHandleChangePage = (e) => {
    setStatePage(e);
  };
  const onHandleChangePageSize = (e) => {
    setStateLimit(e);
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((selectedId) =>
      stateAccount
        .map((obj, index) => ({
          ...obj,
          id: obj._id,
        }))
        .find((row) => row.id === selectedId)
    );
    setSelectedRow(selectedRows);

    setSelectionModel(newSelectionModel);
  };
  const VERIFY_ACCOUNT_USER = useMutation({
    mutationFn: (body) => {
      return VERIFY_ACCOUNT(body);
    },
  });

  const handleAccess = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_verify: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_VERIFIED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_verify);
                  DATA_ALL_USER.refetch();
                  setIsloading({
                    ...Isloading,
                    loading_verify: false,
                  });
                  setSelectedRow([]);
                  setSelectionModel([]);
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_verify: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };

  const handleBANNED = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_ban: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_BANNED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_ban);
                  DATA_ALL_USER.refetch();
                  setSelectedRow([]);
                  setSelectionModel([]);
                  setIsloading({
                    ...Isloading,
                    loading_ban: false,
                  });
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_ban: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  const handleUNVERIFY = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_unverify: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_UNVERIFIED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_unverify);
                  DATA_ALL_USER.refetch();
                  setSelectedRow([]);
                  setSelectionModel([]);
                  setIsloading({
                    ...Isloading,
                    loading_unverify: false,
                  });
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_unverify: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  return (
    <Box
      m="20px"
      height={"90vh"}
      sx={{
        overflowX: "hidden",

        overflowY: "auto",
      }}
    >
      <Header title="Đại lý" />
      <ButtonGroup variant="contained" aria-label="Basic button group">
        {Isloading.loading_verify ? (
          <Button color="success">
            <LoginIcon sx={{ color: "white" }}></LoginIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              <CircularProgress />
            </Typography>
          </Button>
        ) : (
          <Button onClick={handleAccess} color="success">
            <LoginIcon sx={{ color: "white" }}></LoginIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              DUYỆT
            </Typography>
          </Button>
        )}
        {Isloading.loading_unverify ? (
          <Button color="info">
            <CancelScheduleSendIcon
              sx={{ color: "white" }}
            ></CancelScheduleSendIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              <CircularProgress />
            </Typography>
          </Button>
        ) : (
          <Button onClick={handleUNVERIFY} color="info">
            <CancelScheduleSendIcon
              sx={{ color: "white" }}
            ></CancelScheduleSendIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              HUỶ DUYỆT
            </Typography>
          </Button>
        )}
        {Isloading.loading_ban ? (
          <Button color="error">
            <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
            <Typography ml={1} sx={{ fontSize: "1rem", fontWeight: "700" }}>
              {" "}
              <CircularProgress />
            </Typography>
          </Button>
        ) : (
          <Button onClick={handleBANNED} color="error">
            <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
            <Typography ml={1} sx={{ fontSize: "1rem", fontWeight: "700" }}>
              {" "}
              CẤM TÀI KHOẢN
            </Typography>
          </Button>
        )}
      </ButtonGroup>
      <Box mt={4} mb={2} width={"200px"}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Chọn loại tài khoản
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={stateRole}
            onChange={(e) => {
              setStateRole(e.target.value);
              setStatePage(0);
            }}
          >
            <MenuItem value={process.env.REACT_APP_ROLE_SELLER}>
              Tài khoản SELLER (BIZ)
            </MenuItem>
            <MenuItem value={process.env.REACT_APP_ROLE_PDP}>
              Tài khoản PDP
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mt={2} className="col-5">
        <SearchComponent
          value={stateName}
          setValue={setStateName}
          placeholder={"Tìm kiếm tên tài khoản...."}
        ></SearchComponent>
      </Box>
      <Box
        m="40px 0 0 0"
        height="65vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "1.2rem",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "1rem",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box
          mb={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={1}
        >
          <Box
            display={"flex"}
            gap={3}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <div className="custom-datepickerT">
              <label htmlFor="datePicker" className="datepicker-label">
                <Typography fontWeight={600}>START DATE :</Typography>
              </label>
              <DatePicker
                id="datePicker" // Thêm id cho label
                value={currentDateF}
                onChange={setCurrentDateF}
                format="yyyy-MM-dd" // Chỉ hiển thị năm, tháng và ngày
                locale="vi"
                showNavigation
                clearIcon={null} // Ẩn biểu tượng "X" (clear icon)
              />
            </div>
            <div className="custom-datepickerT">
              <label htmlFor="datePicker" className="datepicker-label">
                <Typography fontWeight={600}>END DATE :</Typography>
              </label>
              <DatePicker
                id="datePicker" // Thêm id cho label
                className="col-6 "
                value={currentDateT}
                onChange={setCurrentDateT}
                format="yyyy-MM-dd" // Chỉ hiển thị năm, tháng và ngày
                locale="vi"
                showNavigation
                clearIcon={null} // Ẩn biểu tượng "X" (clear icon)
              />
            </div>
            <ButtonGroup aria-label="Basic button group" sx={{ gap: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  padding: "10px 20px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  backgroundColor: "#2196f3",
                  "&:hover": {
                    backgroundColor: "#1976d2",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  HandleExportToExcel(
                    Export_excel_columns_KR,
                    stateListExcel,
                    `계정 통계 - ${
                      parseInt(stateRole) ==
                      parseInt(process.env.REACT_APP_ROLE_SELLER)
                        ? "SELLER"
                        : "PDP"
                    }`,
                    true
                  );
                }}
                startIcon={
                  <Avatar
                    src={
                      "https://res.cloudinary.com/dhscgjuis/image/upload/v1728895581/Flag/oi0wqme0pmthelafj6u9.png"
                    }
                  />
                } // Adding the icon to the left
              >
                {DATA_GET_ALL_DATA_FOR_EXCEL?.isFetching ? (
                  <CircularProgress></CircularProgress>
                ) : (
                  "엑셀 내보내기 KR"
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px 20px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  backgroundColor: "#4caf50",
                  "&:hover": {
                    backgroundColor: "#45a049",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  HandleExportToExcel(
                    Export_excel_columns_VN,
                    stateListExcel,
                    `Thống kê tài khoản - ${
                      parseInt(stateRole) ==
                      parseInt(process.env.REACT_APP_ROLE_SELLER)
                        ? "SELLER"
                        : "PDP"
                    }`,
                    true
                  );
                }}
                startIcon={
                  <Avatar
                    src={
                      "https://res.cloudinary.com/dhscgjuis/image/upload/v1728895580/Flag/sio0rjjcpqb4sraio15z.png"
                    }
                  />
                } // Adding the icon to the left
              >
                {DATA_GET_ALL_DATA_FOR_EXCEL?.isFetching ? (
                  <CircularProgress></CircularProgress>
                ) : (
                  "Xuất Excel VN"
                )}
              </Button>
            </ButtonGroup>
            <Typography fontSize={"1.2rem"}>
              Data :{" "}
              {DATA_GET_ALL_DATA_FOR_EXCEL?.data?.data?.data?.total_account ||
                0}
            </Typography>
          </Box>
        </Box>
        <DataGrid
          rows={stateAccount}
          columns={Columns()}
          page={statePage}
          pageSize={stateLimit}
          rowCount={stateTotalAccount}
          paginationMode="server"
          checkboxSelection
          onPageChange={onHandleChangePage}
          onPageSizeChange={onHandleChangePageSize}
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          localeText={{
            toolbarFilters: "Chọn lọc",
            toolbarDensity: "Mật độ",
          }}
          components={{ Toolbar: CustomToolbar }}
        />
      </Box>
    </Box>
  );
};

export default AccountManage;
