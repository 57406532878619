import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Stack,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";

import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";

import {
  columns,
  SelectOldNewOrder,
  UnstyledSelectIntroduction,
  Export_excel_columns_VN,
  Export_excel_columns_KR,
} from "./components/components";

import { CustomToolbar } from "../../components/function";

import { GET_ALL_DATA } from "../../apis/handleDataCenter";
import { GET_ALL_DATA_BY_CREATE_AT } from "../../apis/handleDataCenter";
import {
  CustomLoadingOverlay,
  SearchComponent,
} from "../../components/components";
import { CustomNoRowsOverlay } from "../../components/components";

import { HandleExportToExcel } from "../../components/function";
import DatePicker from "react-date-picker";

const DATACENTER = ({ data }) => {
  const [stateAccount, setStateAccount] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [stateListUpgrade, setStateListUpgrade] = useState([]);
  const [stateListExcel, setStateListExcel] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [currentDateF, setCurrentDateF] = useState(new Date());
  const [currentDateT, setCurrentDateT] = useState(new Date());
  const [statePage, setStatePage] = useState(0);
  const [stateLimit, setStateLimit] = useState(15);
  const [stateName, setStateName] = useState("");
  const [stateSort, setStateSort] = useState("asc");
  const [stateDate, setStateDate] = useState("latest");
  const [stateStatus, setStateStatus] = useState("");
  const [Isloading, setIsloading] = useState({
    loading_verify: false,
    loading_unverify: false,
    loading_ban: false,
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const DATA_GET_ALL_DATA = useQuery({
    queryKey: ["GET_ALL_DATA", statePage, stateStatus, stateDate, stateName],
    queryFn: async () => {
      const res = await GET_ALL_DATA({
        page: statePage + 1,
        limit: stateLimit,
        name: stateName,
        date: stateDate,
        sort: stateSort,
        status: stateStatus,
      });
      if (res?.data?.data?.forms) {
        let newArray = res?.data?.data?.forms.map((item, index) => {
          return {
            ...item,
            id: item?._id,
          };
        });
        // setStatePage(res?.data?.data?.page);

        setStateListUpgrade(newArray);
        setRowCount(parseInt(res?.data?.data?.total_forms));
      }

      return res;
    },
  });
  const DATA_GET_ALL_DATA_FOR_EXCEL = useQuery({
    queryKey: ["GET_ALL_DATA_EXCEL", currentDateF, currentDateT],
    queryFn: async () => {
      let dateF =
        currentDateF.getFullYear() +
        "-" +
        String(currentDateF.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(currentDateF.getDate()).padStart(2, "0");

      let dateT =
        currentDateT.getFullYear() +
        "-" +
        String(currentDateT.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(currentDateT.getDate()).padStart(2, "0");

      const res = await GET_ALL_DATA_BY_CREATE_AT({
        date: stateDate,
        sort: stateSort,
        startTime: dateF,
        endTime: dateT,
      });
      if (res?.data?.data?.forms) {
        // setStatePage(res?.data?.data?.page);

        setStateListExcel(res?.data?.data?.forms);
      }

      return res;
    },
  });

  const onHandleChangePage = (e) => {
    setStatePage(e);
  };
  const onHandleChangePageSize = (e) => {
    setStateLimit(e);
  };
  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((selectedId) =>
      stateAccount
        .map((obj, index) => ({
          ...obj,
          id: obj._id,
        }))
        .find((row) => row.id === selectedId)
    );
    setSelectedRow(selectedRows);

    setSelectionModel(newSelectionModel);
  };

  // Hàm xuất file Excel

  return (
    <Box m="20px">
      <Header
        title="Quản lý dữ liệu người tham gia"
        subtitle={"Tất cả dữ liệu khác hàng"}
      />

      <Box mt={2} className="col-5">
        <SearchComponent
          value={stateName}
          setValue={setStateName}
          placeholder={"Tìm kiếm tên khách hàng...."}
        ></SearchComponent>
      </Box>
      <br></br>
      <Stack
        direction="row"
        width={"50%"}
        // divider={<Divider orientation="vertical" flexItem />}
        justifyContent={"space-between"}
      >
        <Box
          p={2}
          flex={1}
          textAlign={"center"}
          borderRight={"solid 1px #f7f7f7"}
          paddingLeft={"0px"}
        >
          <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
            Chọn lọc
          </Typography>
          <SelectOldNewOrder
            setStatePage={setStatePage}
            setStateSortDate={setStateDate}
            stateSortDate={stateDate}
          ></SelectOldNewOrder>
        </Box>
        <Box p={2} flex={1} textAlign={"center"}>
          {" "}
          <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
            Tình Trạng
          </Typography>
          <Typography fontWeight={600}>
            <UnstyledSelectIntroduction
              stateStatus={stateStatus}
              setStatePage={setStatePage}
              setStateStatus={setStateStatus}
              // handleRefreshData={handleRefreshData}
            ></UnstyledSelectIntroduction>
          </Typography>
        </Box>
      </Stack>
      <br></br>

      <Box
        m="40px 0 0 0"
        height="52vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "1.4rem",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "1.2rem",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box
          mb={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={1}
        >
          <Box
            display={"flex"}
            gap={3}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <div className="custom-datepickerT">
              <label htmlFor="datePicker" className="datepicker-label">
                <Typography fontWeight={600}>START DATE :</Typography>
              </label>
              <DatePicker
                id="datePicker" // Thêm id cho label
                value={currentDateF}
                onChange={setCurrentDateF}
                format="yyyy-MM-dd" // Chỉ hiển thị năm, tháng và ngày
                locale="vi"
                showNavigation
                clearIcon={null} // Ẩn biểu tượng "X" (clear icon)
              />
            </div>
            <div className="custom-datepickerT">
              <label htmlFor="datePicker" className="datepicker-label">
                <Typography fontWeight={600}>END DATE :</Typography>
              </label>
              <DatePicker
                id="datePicker" // Thêm id cho label
                className="col-6 "
                value={currentDateT}
                onChange={setCurrentDateT}
                format="yyyy-MM-dd" // Chỉ hiển thị năm, tháng và ngày
                locale="vi"
                showNavigation
                clearIcon={null} // Ẩn biểu tượng "X" (clear icon)
              />
            </div>
            <ButtonGroup aria-label="Basic button group" sx={{ gap: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  padding: "10px 20px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  backgroundColor: "#2196f3",
                  "&:hover": {
                    backgroundColor: "#1976d2",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  HandleExportToExcel(
                    Export_excel_columns_KR,
                    stateListExcel,
                    "데이터 센터 통계"
                  );
                }}
                startIcon={
                  <Avatar
                    src={
                      "https://res.cloudinary.com/dhscgjuis/image/upload/v1728895581/Flag/oi0wqme0pmthelafj6u9.png"
                    }
                  />
                } // Adding the icon to the left
              >
                {DATA_GET_ALL_DATA_FOR_EXCEL?.isFetching ? (
                  <CircularProgress></CircularProgress>
                ) : (
                  "엑셀 내보내기 KR"
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px 20px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  backgroundColor: "#4caf50",
                  "&:hover": {
                    backgroundColor: "#45a049",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  HandleExportToExcel(
                    Export_excel_columns_VN,
                    stateListExcel,
                    "Thống kê Data center"
                  );
                }}
                startIcon={
                  <Avatar
                    src={
                      "https://res.cloudinary.com/dhscgjuis/image/upload/v1728895580/Flag/sio0rjjcpqb4sraio15z.png"
                    }
                  />
                } // Adding the icon to the left
              >
                {DATA_GET_ALL_DATA_FOR_EXCEL?.isFetching ? (
                  <CircularProgress></CircularProgress>
                ) : (
                  "Xuất Excel VN"
                )}
              </Button>
            </ButtonGroup>
            <Typography fontSize={"1.2rem"}>
              Data :{" "}
              {DATA_GET_ALL_DATA_FOR_EXCEL?.data?.data?.data?.total_forms || 0}
            </Typography>
          </Box>
        </Box>
        <DataGrid
          rows={stateListUpgrade || []}
          columns={columns}
          pagination
          page={statePage}
          pageSize={stateLimit}
          rowCount={rowCount}
          paginationMode="server"
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          onPageChange={onHandleChangePage}
          onPageSizeChange={onHandleChangePageSize}
          loading={DATA_GET_ALL_DATA?.isFetching}
          localeText={{
            toolbarFilters: "Chọn lọc",
            toolbarDensity: "Mật độ",
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
    </Box>
  );
};

export default DATACENTER;
