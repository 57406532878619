import {
  Box,
  Button,
  Chip,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import DatePicker from "react-date-picker";
import SearchIcon from "@mui/icons-material/Search";

import { DataGrid } from "@mui/x-data-grid";
import { columnsPDP, columnsSELLER } from "./component/components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Handle_Statistic_PDP_Year_Month_day } from "../../apis/handlePurchase";
import StatisticSeller from "./StatisticSELLER";
import { CustomToolbar } from "../../components/function";
import { SearchComponent } from "../../components/components";
import { getMonthNameInVietnamese } from "../dashboard/components/conponents";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
const StatisticPDP = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const today = new Date();

  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0, cần +1

  const formattedDateF = `${year}-${month}-${String(
    startOfMonth.getDate()
  ).padStart(2, "0")}`;
  const formattedDateT = `${year}-${month}-${String(
    endOfMonth.getDate()
  ).padStart(2, "0")}`;
  const formattedDate = `${getMonthNameInVietnamese(
    currentDate.getMonth()
  )}-${currentDate.getFullYear()}`;

  const [dateF, onChangeF] = useState(formattedDateF);
  const [dateT, onChangeT] = useState(formattedDateT);
  const [statePage, setStatePage] = useState(0);
  const [stateLimit, setStateLimit] = useState(30);
  const [stateName, setStateName] = useState("");
  const [stateRowCount, setStateRowCount] = useState(0);
  const [stateSearchPurchaseSeller, setStateSearchPurchaseSeller] = useState(
    []
  );

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleIncrease = async () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);
    if (newDate.getMonth() === 0) {
      newDate.setFullYear(newDate.getFullYear());
    }

    const startOfMonth = new Date(newDate.getFullYear(), newDate.getMonth(), 1);

    const endOfMonth = new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      0
    );

    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0, cần +1

    const formattedDateF = `${year}-${month}-${String(
      startOfMonth.getDate()
    ).padStart(2, "0")}`;
    const formattedDateT = `${year}-${month}-${String(
      endOfMonth.getDate()
    ).padStart(2, "0")}`;
    let FormGet = {
      startTime: formattedDateF,
      endTime: formattedDateT,
      limit: stateLimit,
      page: statePage + 1,
      name: stateName,
    };
    HANDLE_GET_DATA_PURCHASE_SELLER.mutate(FormGet, {
      onSuccess: (data) => {
        if (data?.data) {
          let FilterData = data?.data?.users.map((item, index) => {
            return {
              ...item,
              id: item?.user_id,
            };
          });
          setStateRowCount(data?.data?.total_page);
          setStateSearchPurchaseSeller(FilterData);
        }
      },
      onError: (err) => {
        console.log("err " + err);
      },
    });
    onChangeF(formattedDateF);
    onChangeT(formattedDateT);
    setCurrentDate(newDate);
  };
  const handleDecrease = async () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);

    if (newDate.getMonth() === 11) {
      newDate.setFullYear(newDate.getFullYear());
    }
    const startOfMonth = new Date(newDate.getFullYear(), newDate.getMonth(), 1);

    const endOfMonth = new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      0
    );

    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0, cần +1

    const formattedDateF = `${year}-${month}-${String(
      startOfMonth.getDate()
    ).padStart(2, "0")}`;
    const formattedDateT = `${year}-${month}-${String(
      endOfMonth.getDate()
    ).padStart(2, "0")}`;
    let FormGet = {
      startTime: formattedDateF,
      endTime: formattedDateT,
      limit: stateLimit,
      page: statePage + 1,
      name: stateName,
    };
    HANDLE_GET_DATA_PURCHASE_SELLER.mutate(FormGet, {
      onSuccess: (data) => {
        if (data?.data) {
          let FilterData = data?.data?.users.map((item, index) => {
            return {
              ...item,
              id: item?.user_id,
            };
          });
          setStateRowCount(data?.data?.total_page);
          setStateSearchPurchaseSeller(FilterData);
        }
      },
      onError: (err) => {
        console.log("err " + err);
      },
    });
    onChangeF(formattedDateF);
    onChangeT(formattedDateT);
    setCurrentDate(newDate);
  };
  const HANDLE_GET_DATA_PURCHASE_SELLER = useMutation({
    mutationKey: ["STATISTIC_PDP"],
    mutationFn: (body) => {
      return Handle_Statistic_PDP_Year_Month_day(body);
    },
  });

  const handleSearchPDP = () => {
    let FormGet = {
      startTime: dateF,
      endTime: dateT,
      limit: stateLimit,
      page: statePage + 1,
      name: stateName,
    };
    HANDLE_GET_DATA_PURCHASE_SELLER.mutate(FormGet, {
      onSuccess: (data) => {
        if (data?.data) {
          let FilterData = data?.data?.users.map((item, index) => {
            return {
              ...item,
              id: item?.user_id,
            };
          });
          setStateRowCount(data?.data?.total_page);
          setStateSearchPurchaseSeller(FilterData);
        }
      },
      onError: (err) => {
        console.log("err " + err);
      },
    });
  };

  const onHandleChangePage = (e) => {
    setStatePage(e);
    let FormGet = {
      startTime: dateF,
      endTime: dateT,
      limit: stateLimit,
      page: e + 1,
      name: stateName,
    };
    HANDLE_GET_DATA_PURCHASE_SELLER.mutate(FormGet, {
      onSuccess: (data) => {
        if (data?.data) {
          let FilterData = data?.data?.users.map((item, index) => {
            return {
              ...item,
              id: item?.user_id,
            };
          });
          setStateRowCount(data?.data?.total_page);
          setStateSearchPurchaseSeller(FilterData);
        }
      },
      onError: (err) => {
        console.log("err " + err);
      },
    });
  };
  const columnsShowSeller = columnsPDP();
  useEffect(() => {
    handleSearchPDP();
  }, []);
  return (
    <>
      <Box
        m="20px"
        height={"90vh"}
        sx={{
          overflowX: "hidden",

          overflowY: "auto",
        }}
      >
        <Divider>
          <Chip label="Thống kê hoạt động PDP" size="medium" />
        </Divider>

        <Box
          m="20px 20px 20px 0px"
          width={"400px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            onClick={() => {
              handleDecrease();
            }}
            sx={{ backgroundColor: "green" }}
            variant="contained"
          >
            <ArrowLeftIcon fontSize="large"></ArrowLeftIcon>
          </Button>
          <Typography>{formattedDate} </Typography>
          <Button
            onClick={() => {
              handleIncrease();
            }}
            sx={{ backgroundColor: "green" }}
            variant="contained"
          >
            <ArrowRightIcon fontSize="large"></ArrowRightIcon>
          </Button>
        </Box>
        <Box mt={2} display={"flex"} gap={3} className="col-3">
          <SearchComponent
            value={stateName}
            setValue={setStateName}
            placeholder={"Tìm kiếm tên nhà cung cấp...."}
          ></SearchComponent>
          <Box>
            <button onClick={handleSearchPDP} className="btn btn-info">
              <SearchIcon
                sx={{ color: "white" }}
                fontSize="medium"
              ></SearchIcon>
            </button>
          </Box>
        </Box>

        <Box mt={2}>
          <Box
            m="40px 20px 0 0"
            height="55vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
                fontSize: "1.1rem",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                fontSize: "1rem",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              rows={stateSearchPurchaseSeller || []}
              columns={columnsShowSeller}
              page={statePage}
              pageSize={stateLimit}
              rowCount={stateRowCount}
              onPageChange={onHandleChangePage}
              localeText={{
                toolbarFilters: "Chọn lọc",
                toolbarDensity: "Mật độ",
              }}
              components={{ Toolbar: CustomToolbar }}
            />
          </Box>
        </Box>
        <StatisticSeller></StatisticSeller>
      </Box>
    </>
  );
};
export default StatisticPDP;
