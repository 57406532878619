import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Input,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { columns } from "./components/components";
import { useLocation, useParams } from "react-router-dom";
import { GET_SELLER_ACCOUNT_OF_SELLER } from "../../apis/handleAccount";
import CircularProgress from "@mui/material/CircularProgress";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import LoginIcon from "@mui/icons-material/Login";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { VERIFY_ACCOUNT, CHANGEPASS_ACCOUNT } from "../../apis/handleAccount";
import {
  notification_verify,
  notification_unverify,
  notification_ban,
} from "./components/components";
import { Store } from "react-notifications-component";
import { Label } from "@mui/icons-material";
const AccountJoin = () => {
  const { accountid } = useParams();
  const { state } = useLocation();

  const [Isloading, setIsloading] = useState({
    loading_verify: false,
    loading_unverify: false,
    loading_ban: false,
  });
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [statePage, setStatePage] = useState(0);
  const [stateLimit, setStateLimit] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [stateProvider, setStateProvider] = useState([]);

  const [showPassword, setShowPassword] = useState(true);
  const [stateErrors, setStateErrors] = useState({});
  const [stateAccount, setStateAccount] = useState([]);
  const [stateProviderID, setStateProviderID] = useState("");
  const [IsloadingChangePassword, setIsloadingChangePassword] = useState(false);
  const [changePass, setChangePass] = useState({
    newpassworld: "",
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const VERIFY_ACCOUNT_USER = useMutation({
    mutationFn: (body) => {
      return VERIFY_ACCOUNT(body);
    },
  });
  const CHANGE_PASSSWORD_ACCOUNT_USER = useMutation({
    mutationFn: (body) => {
      return CHANGEPASS_ACCOUNT(body);
    },
  });
  const DATA_ALL_USER_OF_ACCOUNT = useQuery({
    queryKey: ["GET_ALL_ACCOUNT_SELLER"],
    queryFn: async () => {
      const result = await GET_SELLER_ACCOUNT_OF_SELLER({
        seller_id: accountid,
      });
      if (result?.data) {
        setStateAccount(result?.data);
      }
      return result;
    },
  });
  const validate = () => {
    const newError = {};

    //validate required
    if (!changePass.newpassworld) {
      newError.newpassworld = "Mật khẩu không được để trống";
    }

    setStateErrors(newError);

    return Object.keys(newError).length === 0;
  };
  const generateRandomString = (length) => {
    // Các loại ký tự
    const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialCharacters = "!@#$%^&*()";

    // Đảm bảo ít nhất một ký tự từ mỗi loại
    let result = "";
    result +=
      uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
    result +=
      lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
    result += numbers[Math.floor(Math.random() * numbers.length)];
    result +=
      specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

    // Tạo danh sách tất cả các ký tự có thể
    const allCharacters =
      uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

    // Thêm các ký tự ngẫu nhiên để đạt độ dài mong muốn
    for (let i = result.length; i < length; i++) {
      result += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    // Trộn ngẫu nhiên các ký tự trong chuỗi
    result = result
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");

    setChangePass({
      newpassworld: result,
    });
    return result;
  };
  const handleChangePass = (e) => {
    setChangePass({
      ...changePass,
      [e.target.name]: e.target.value,
    });
  };
  const handleCheckboxChange = () => {
    setShowPassword(!showPassword);
  };
  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((selectedId) =>
      stateAccount?.data.find((row) => row._id === selectedId)
    );
    setSelectedRow(selectedRows);

    setSelectionModel(newSelectionModel);
  };
  const CHANGE_PASS = async () => {
    let Form = {
      user_id: accountid,
      new_password: changePass?.newpassworld,
    };
    if (validate()) {
      CHANGE_PASSSWORD_ACCOUNT_USER.mutate(Form, {
        onSuccess: (data) => {
          if (data?.errors) {
            setStateErrors(data);
          } else {
            alert("Mật khẩu tài khoản đã đổi thành công");
          }
        },
        onError: (err) => {
          console.log("err ", JSON.stringify(err));
        },
      });
    }
  };
  const handleAccess = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_verify: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_VERIFIED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_verify);
                  DATA_ALL_USER_OF_ACCOUNT.refetch();
                  setIsloading({
                    ...Isloading,
                    loading_verify: false,
                  });
                  setSelectedRow([]);
                  setSelectionModel([]);
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_verify: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };

  const handleBANNED = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_ban: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_BANNED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_ban);
                  DATA_ALL_USER_OF_ACCOUNT.refetch();
                  setSelectedRow([]);
                  setSelectionModel([]);
                  setIsloading({
                    ...Isloading,
                    loading_ban: false,
                  });
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_ban: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  const handleUNVERIFY = async () => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn thông tin tài khoản");
    } else {
      try {
        setIsloading({
          ...Isloading,
          loading_unverify: true,
        });
        await Promise.all(
          selectedRow.map((body) =>
            VERIFY_ACCOUNT_USER.mutate(
              {
                id: body._id,
                verify: parseInt(process.env.REACT_APP_ACCOUNT_UNVERIFIED),
              },
              {
                onSuccess: (data) => {
                  Store.addNotification(notification_unverify);
                  DATA_ALL_USER_OF_ACCOUNT.refetch();
                  setSelectedRow([]);
                  setSelectionModel([]);
                  setIsloading({
                    ...Isloading,
                    loading_unverify: false,
                  });
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_unverify: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  const onHandleChangePage = (e) => {
    setStatePage(e);
    let formGet = {
      limit: stateLimit,
      page: e + 1,
      provider_id: stateProviderID,
    };
  };
  const onHandleChangePageSize = (e) => {
    setStateLimit(e);
  };

  return (
    <>
      <Box
        m="20px"
        height={"90vh"}
        sx={{
          overflowX: "hidden",

          overflowY: "auto",
        }}
      >
        <Header title="QUẢN LÝ TÀI KHOẢN CỦA SELLER" />
        <Box mb={2} className="header-details">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/#/quan-ly-tai-khoan">
              Quản lý tài khoản
            </Link>

            <Typography color="text.primary">
              Chi tiết tài khoản tham gia
            </Typography>
          </Breadcrumbs>
        </Box>
        <Box ml={0} gap={1} className="row">
          <Box
            className="col-5"
            mb={2}
            height={"auto"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "#1f2a40",
              width: "35vw",
            }}
            p={3}
            mt={2}
          >
            <Header title="Thông tin tài khoản" subtitle="" />
            <Avatar
              src={stateAccount?.seller_info?.avatar}
              sx={{ width: 80, height: 80 }}
            ></Avatar>
            <Typography fontSize={"1.2rem"} fontWeight={700}>
              {stateAccount?.seller_info?.name}
            </Typography>
          </Box>
          {stateAccount?.ref_info?.avatar ? (
            <Box
              className="col-5"
              mb={2}
              height={"auto"}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "#1f2a40",
                width: "35vw",
              }}
              p={3}
              mt={2}
            >
              <Header title="Người giới thiệu" subtitle="" />
              <Avatar
                src={stateAccount?.ref_info?.avatar}
                sx={{ width: 80, height: 80 }}
              ></Avatar>
              <Typography fontSize={"1.2rem"} fontWeight={700}>
                {stateAccount?.ref_info?.name}
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Box>

        <Box
          mb={2}
          height={"auto"}
          sx={{ bgcolor: "#1f2a40", width: "35vw" }}
          p={3}
          mt={2}
        >
          <Header title="Thay đổi mật khẩu" subtitle="" />
          <Box mb={2}>
            <label style={{ fontSize: "16px" }}>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={handleCheckboxChange}
              />
              Hiển thị mật khẩu
            </label>
          </Box>
          <Box style={{ width: "30vw" }}>
            <Box
              display="flex"
              flexDirection={"column"}
              justifyContent={"space-between"}
              gap="30px"
            >
              <Box display={"flex"} justifyContent={"space-between"}>
                {" "}
                <Typography>Mật khẩu mới</Typography>
                <Input
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  name="newpassworld"
                  onChange={handleChangePass}
                  value={changePass?.newpassworld}
                  sx={{ gridColumn: "span 2", paddingLeft: "10px" }}
                />
              </Box>
              {stateErrors && stateErrors?.newpassworld ? (
                <Alert style={{ marginTop: "1rem" }} severity="error">
                  {stateErrors?.newpassworld}
                </Alert>
              ) : (
                ""
              )}
              {stateErrors && stateErrors.errors?.new_password?.msg ? (
                <Alert style={{ marginTop: "1rem" }} severity="error">
                  {stateErrors.errors?.new_password?.msg}
                </Alert>
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" justifyContent="space-between" mt="20px">
              <Button
                color="info"
                sx={{ fontWeight: "700", fontSize: "16px", color: "white" }}
                variant="contained"
                onClick={() => {
                  generateRandomString(10);
                }}
              >
                Tạo Mật khẩu ngẫu nhiên
              </Button>
              {IsloadingChangePassword ? (
                <Button
                  color="secondary"
                  sx={{ fontWeight: "700", fontSize: "16px", color: "white" }}
                  variant="contained"
                >
                  <CircularProgress></CircularProgress>
                </Button>
              ) : (
                <Button
                  color="secondary"
                  sx={{ fontWeight: "700", fontSize: "16px", color: "white" }}
                  variant="contained"
                  onClick={CHANGE_PASS}
                >
                  Đổi mật khẩu
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        <ButtonGroup variant="contained" aria-label="Basic button group">
          {Isloading.loading_verify ? (
            <Button color="success">
              <LoginIcon sx={{ color: "white" }}></LoginIcon>
              <Typography
                ml={1}
                sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
              >
                {" "}
                <CircularProgress />
              </Typography>
            </Button>
          ) : (
            <Button onClick={handleAccess} color="success">
              <LoginIcon sx={{ color: "white" }}></LoginIcon>
              <Typography
                ml={1}
                sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
              >
                {" "}
                DUYỆT
              </Typography>
            </Button>
          )}
          {Isloading.loading_unverify ? (
            <Button color="info">
              <CancelScheduleSendIcon
                sx={{ color: "white" }}
              ></CancelScheduleSendIcon>
              <Typography
                ml={1}
                sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
              >
                {" "}
                <CircularProgress />
              </Typography>
            </Button>
          ) : (
            <Button onClick={handleUNVERIFY} color="info">
              <CancelScheduleSendIcon
                sx={{ color: "white" }}
              ></CancelScheduleSendIcon>
              <Typography
                ml={1}
                sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
              >
                {" "}
                HUỶ DUYỆT
              </Typography>
            </Button>
          )}
          {Isloading.loading_ban ? (
            <Button color="error">
              <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
              <Typography ml={1} sx={{ fontSize: "1rem", fontWeight: "700" }}>
                {" "}
                <CircularProgress />
              </Typography>
            </Button>
          ) : (
            <Button onClick={handleBANNED} color="error">
              <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
              <Typography ml={1} sx={{ fontSize: "1rem", fontWeight: "700" }}>
                {" "}
                CẤM TÀI KHOẢN
              </Typography>
            </Button>
          )}
        </ButtonGroup>
        {DATA_ALL_USER_OF_ACCOUNT.isFetching ? (
          <Box display={"flex"} width={"100%"} height={"100%"}>
            <Box margin={"auto"} fontSize={"1.2rem"}>
              Đang trong quá trình lấy dữ liệu vui lòng chờ.....
            </Box>
          </Box>
        ) : (
          <>
            {" "}
            {stateAccount?.data && stateAccount?.data[0]?._id ? (
              <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontSize: "1.2rem",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                    fontSize: "1rem",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                  },
                }}
              >
                <DataGrid
                  pagination
                  page={statePage}
                  pageSize={stateLimit}
                  rowCount={rowCount}
                  paginationMode="server"
                  checkboxSelection
                  onPageChange={onHandleChangePage}
                  onPageSizeChange={onHandleChangePageSize}
                  selectionModel={selectionModel}
                  onSelectionModelChange={handleSelectionModelChange}
                  rows={
                    (stateAccount?.data &&
                      stateAccount?.data.map((item, index) => {
                        return {
                          ...item,
                          id: item._id,
                        };
                      })) ||
                    []
                  }
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                />
              </Box>
            ) : (
              <Box display={"flex"} width={"100%"} height={"100%"}>
                <Box margin={"auto"} fontSize={"1.2rem"}>
                  **Hiện tại tài khoản này chưa có người tham gia !
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default AccountJoin;
