import { Box, Typography } from "@mui/material";
import { CaseDatetimeToYYYYMMDDHHMMSS } from "../../../components/function";
import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import styled from "styled-components";

export const columns = [
  {
    field: "code_upgrade",
    headerName: "Mã giao dịch ",
    width: 200,
    renderCell: (params) => (
      <>
        {params?.row?.is_sent_payment ? (
          <a
            style={{ fontWeight: "700" }}
            href={`/#/chi-tiet-nang-cap/${params.row.id}`}
          >
            {params?.value}
          </a>
        ) : (
          <Typography>{params?.value}</Typography>
        )}
      </>
    ),
  },
  {
    field: "created_at",
    headerName: "Thời điểm tạo",
    width: 200,
    renderCell: (params) => (
      <div>{CaseDatetimeToYYYYMMDDHHMMSS(params?.value)}</div>
    ),
  },

  {
    field: "seller_info",
    headerName: "Thông tin tài khoản",
    width: 400,
    renderCell: (params) => (
      <>
        <Box color={"#ffc5f2"} display={"flex"}>
          <Typography fontSize={"1rem"}>id : {params?.value?.phone}</Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>Tên : {params?.value?.name}</Typography>
        </Box>
      </>
    ),
  },
  {
    field: "status",
    headerName: "Tình trạng",
    width: 200,
    renderCell: (params) => (
      <>
        {parseInt(params?.value) ===
        parseInt(process.env.REACT_APP_STATUS_UPGRADE_PENDING) ? (
          <Typography fontSize={"1.2rem"} color={"orangered"}>
            ĐANG CHỜ XÁC NHẬN
          </Typography>
        ) : parseInt(params?.value) ===
          parseInt(process.env.REACT_APP_STATUS_UPGRADE_ACCEPT) ? (
          <Typography fontSize={"1.2rem"} color={"skyblue"}>
            ĐÃ XÁC NHẬN
          </Typography>
        ) : parseInt(params?.value) ===
          parseInt(process.env.REACT_APP_STATUS_UPGRADE_CANCEL) ? (
          <Typography fontSize={"1.2rem"} color={"red"}>
            ĐÃ HUỶ
          </Typography>
        ) : parseInt(params?.value) ===
          parseInt(process.env.REACT_APP_STATUS_UPGRADE_EXPIRED) ? (
          <Typography fontSize={"1.2rem"} color={"GrayText"}>
            ĐÃ HẾT HẠN
          </Typography>
        ) : (
          ""
        )}
      </>
    ),
  },
  {
    field: "in_use",
    headerName: "Trạng thái",
    width: 200,
    renderCell: (params) => (
      <div>
        {params?.value ? (
          <Typography fontSize={"1.2rem"} color={"green"}>
            Đang dùng
          </Typography>
        ) : (
          <Typography fontSize={"1.2rem"} color={"GrayText"}>
            Không dùng
          </Typography>
        )}
      </div>
    ),
  },
  {
    field: "is_sent_payment",
    headerName: "Hoá đơn",
    width: 200,
    renderCell: (params) => (
      <div>
        {params?.value ? (
          <Typography fontSize={"1.2rem"} p={2} bgcolor={"#008000"}>
            Đã thanh toán
          </Typography>
        ) : (
          <Typography fontSize={"1.2rem"} p={2} bgcolor={"gray"}>
            Chưa thanh toán
          </Typography>
        )}
      </div>
    ),
    cellClassName: "column-sticky",
  },

  {
    field: "package_info",
    headerName: "Gói nâng cấp",
    width: 400,
    renderCell: (params) => (
      <>
        <Box color={"#c2ffc5"} display={"flex"}>
          {" "}
          <Typography fontSize={"1rem"}>
            Gói nâng cấp : {params?.value?.name}
          </Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>
            Thời hạn : {params?.value?.duration_in_months}
          </Typography>
          &emsp;
        </Box>
      </>
    ),
  },
];

export const columnsSearch = [
  {
    field: "code_upgrade",
    headerName: "Mã giao dịch",
    width: 200,
    renderCell: (params) => (
      <a
        style={{ fontWeight: "700" }}
        href={`/#/chi-tiet-nang-cap/${params.row.id}`}
      >
        {params?.value}
      </a>
    ),
  },
  {
    field: "package_info",
    headerName: "Gói nâng cấp",
    width: 450,
    renderCell: (params) => (
      <>
        <Box color={"#c2ffc5"} display={"flex"}>
          {" "}
          <Typography fontSize={"1rem"}>
            Gói nâng cấp : {params?.value?.name}
          </Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>
            Thời hạn : {params?.value?.duration_in_months}
          </Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>
            Giá : {parseInt(params?.value?.price).toLocaleString("en-us")}
          </Typography>
        </Box>
      </>
    ),
  },

  {
    field: "seller_info",
    headerName: "Thông tin tài khoản",
    width: 300,
    renderCell: (params) => (
      <>
        <Box color={"#ffc5f2"} display={"flex"}>
          <Typography fontSize={"1rem"}>Tên : {params?.value?.name}</Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>
            sdt : {params?.value?.phone}
          </Typography>
        </Box>
      </>
    ),
  },
  {
    field: "revenue_distribution",
    headerName: "Thông tin chi tiết",
    width: 500,
    renderCell: (params) => (
      <>
        <Box color={"#ffe5f2"} display={"flex"}>
          <Typography fontSize={"1rem"}>
            Người giới thiệu :{" "}
            {params?.value?.referrer_amount
              ? parseInt(params?.value?.referrer_amount).toLocaleString("en-us")
              : "Không có"}
          </Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>
            Lợi nhuận admin :
            {parseInt(params?.value?.admin_amount).toLocaleString("en-us")}
          </Typography>
        </Box>
      </>
    ),
  },
  {
    field: "status",
    headerName: "Tình trạng",
    width: 200,
    renderCell: (params) => (
      <>
        {parseInt(params?.value) ===
        parseInt(process.env.REACT_APP_STATUS_UPGRADE_PENDING) ? (
          <Typography fontSize={"1.2rem"} color={"orangered"}>
            ĐANG CHỜ XÁC NHẬN
          </Typography>
        ) : parseInt(params?.value) ===
          parseInt(process.env.REACT_APP_STATUS_UPGRADE_ACCEPT) ? (
          <Typography fontSize={"1.2rem"} color={"skyblue"}>
            ĐÃ XÁC NHẬN
          </Typography>
        ) : parseInt(params?.value) ===
          parseInt(process.env.REACT_APP_STATUS_UPGRADE_CANCEL) ? (
          <Typography fontSize={"1.2rem"} color={"red"}>
            ĐÃ NGƯNG HOẠT ĐỘNG
          </Typography>
        ) : parseInt(params?.value) ===
          parseInt(process.env.REACT_APP_STATUS_UPGRADE_EXPIRED) ? (
          <Typography fontSize={"1.2rem"} color={"GrayText"}>
            ĐÃ HẾT HẠN
          </Typography>
        ) : (
          ""
        )}
      </>
    ),
  },
  {
    field: "in_use",
    headerName: "Trạng thái",
    width: 200,
  },
  {
    field: "created_at",
    headerName: "Thời điểm tạo",
    width: 200,
    renderCell: (params) => (
      <div>{CaseDatetimeToYYYYMMDDHHMMSS(params?.value)}</div>
    ),
  },
];
export const notification_verify = {
  title: "Thao tác thành công",
  message: "Thành công duyệt tài khoản !",
  type: "success",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_unverify = {
  title: "Thao tác thành công",
  message: "Thành công huỷ duyệt tài khoản !",
  type: "default",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_ban = {
  title: "Thao tác thành công!",
  message: "Thành công ngừng hoạt động tài khoản !",
  type: "danger",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};

const StyledSelect = styled.select`
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f3f6f9;
  color: #333;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const StyledOption = styled.option`
  padding: 8px;
  background-color: #fff;
  color: #333;
  font-size: 16px;

  &:hover {
    background-color: #f3f6f9;
  }
`;
export const UnstyledSelectIntroduction = ({
  stateStatus,
  setStateStatus,
  handleRefreshData,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateStatus(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateStatus}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={""}>Lấy tất cả đơn yêu cầu</StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_UPGRADE_PENDING)}
        >
          Đang chờ xác nhận
        </StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_UPGRADE_ACCEPT)}
        >
          Đang dùng
        </StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_UPGRADE_EXPIRED)}
        >
          Đã hết hạn
        </StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_UPGRADE_CANCEL)}
        >
          Đã huỷ
        </StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const SelectOldNewOrder = ({
  setStateSortDate,
  stateSortDate,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateSortDate(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateSortDate}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={"latest"}>Mới nhất</StyledOption>
        <StyledOption value={"oldest"}>Cũ nhất</StyledOption>
      </StyledSelect>
    </FormControl>
  );
};
