import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import { ReactNotifications } from "react-notifications-component";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { LOG_OUT } from "../../apis/handleAccount";
import { useMutation } from "@tanstack/react-query";
import { RF_TOKEN_F } from "../../apis/refreshToken";
function Common({ childrend }) {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [render, setRender] = useState(false);
  const [stateShowAdmin, setStateShowAdmin] = useState(true);
  let Nav = useNavigate();

  const childrenWithProps = React.Children.map(childrend, (child) =>
    React.cloneElement(child, {
      setStateShowAdmin: setStateShowAdmin,
      stateShowAdmin: stateShowAdmin,
    })
  );
  const MutationRF = useMutation({
    mutationKey: ["Start"],
    mutationFn: (body) => {
      return RF_TOKEN_F(body);
    },
  });
  const MutationLogout = useMutation({
    mutationKey: ["log_out"],
    mutationFn: (body) => {
      return LOG_OUT(body);
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    const interval = setInterval(() => {
      if (!localStorage.getItem("token")) {
        return Nav("/");
      } else {
        const decodedToken = jwtDecode(localStorage.getItem("token"));
        // const currentTime = Date.now() / 1000; // Chuyển đổi thời gian hiện tại từ milliseconds sang seconds
        if (decodedToken?.roles != process.env.REACT_APP_ROLE_ADMIN) {
          localStorage.clear();
          return Nav("/");
        } else {
          setRender(true);
        }
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      {render ? (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <div className="app">
              <ReactNotifications></ReactNotifications>
              <Sidebar isSidebar={isSidebar} />
              <main className="content">
                <Topbar
                  setStateShowAdmin={setStateShowAdmin}
                  setIsSidebar={setIsSidebar}
                />
                {childrenWithProps}
              </main>
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,
            p: 4,
          }}
        >
          <img
            width={200}
            height={200}
            src={
              "https://global.discourse-cdn.com/sitepoint/original/3X/e/3/e352b26bbfa8b233050087d6cb32667da3ff809c.gif"
            }
          ></img>
        </Box>
      )}
    </>
  );
}

export default Common;
