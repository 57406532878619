import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "react-notifications-component/dist/theme.css";
import "nprogress/nprogress.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "moment/locale/vi"; // Import ngôn ngữ tiếng Việt của moment
import moment from "moment";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HashRouter as Router } from "react-router-dom";
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
moment.locale("vi"); // Cài đặt ngôn ngữ mặc định là tiếng Việt

root.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <App />
       
      </QueryClientProvider>
    </Router>
  </React.StrictMode>
);
