import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState } from "react";
import { REGISTER_USER } from "../../apis/handleAccount";
import { useQuery, useMutation } from "@tanstack/react-query";
import { DataGrid } from "@mui/x-data-grid";
import { Columns, style } from "./components/components";
import { CloseModal, CustomToolbar } from "../../components/function";
import { GET_ALL_CATEGORY } from "../../apis/handleProduct";
import { CREATE_CATEGORY, UPDATE_CATEGORY } from "../../apis/handleCategories";
import { tokens } from "../../theme";
import { Input } from "../product/components/components";
import { CustomNoRowsOverlay } from "../../components/components";
const Categories = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [openEdit, setOpenEdit] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [stateErrors, setStateErrors] = useState({});
  const [isLoading, setIsloadingSubmit] = useState(false);
  const [stateCategory, setStateCategory] = useState([]);
  const [stateCategoryEdit, setStateCategoryEdit] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [stateFormCreate, setStateFormCreate] = useState({
    name: "",
  });
  const [IsloadingEdit, setIsloadingEdit] = useState(false);
  const [Role, setRole] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [stateImg, setStateImg] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const [stateImgEDIT, setStateImgEDIT] = useState("");
  const [imageFileEDIT, setImageFileEDIT] = useState(null);
  const DataCateGory = useQuery({
    queryKey: ["GetAllCategory"],
    queryFn: async () => {
      const result = await GET_ALL_CATEGORY();
      if (result?.data?.data) {
        setStateCategory(result?.data?.data);
      }
      return result; // Ensure the result is returned
    },
  });
  const HANDLE_CREATE_CATEGORY = useMutation({
    mutationFn: (body) => {
      return CREATE_CATEGORY(body);
    },
  });
  const HANDLE_UPDATE_CATEGORY = useMutation({
    mutationFn: (body) => {
      return UPDATE_CATEGORY(body);
    },
  });
  const handleChangeValue = (e) => {
    setStateErrors({
      ...stateErrors,
      [e.target.name]: "",
    });
    setStateFormCreate({
      ...stateFormCreate,
      [e.target.name]: e.target.value,
    });
  };
  const handleUpdateCategory = () => {
    setIsloadingEdit(true);
    try {
      const dataForm = new FormData();

      dataForm.append("name", modalData?.name);
      dataForm.append("id", modalData?._id);
      if (imageFileEDIT) {
        dataForm.append("image", imageFileEDIT);
      }

      HANDLE_UPDATE_CATEGORY.mutate(dataForm, {
        onSuccess: (data) => {
          if (!data?.errors) {
            setOpen(false);
            DataCateGory.refetch();
            setImageFileEDIT(null);
            setStateImgEDIT("");
            alert("Update success");
            setIsloadingEdit(false);
          } else {
            setIsloadingEdit(false);
          }
        },
        onError: (err) => {
          setIsloadingEdit(false);
          console.log("err " + err);
        },
      });
    } catch (error) {
      setIsloadingEdit(false);
    }
  };
  const handleCreateCategory = () => {
    setIsloadingSubmit(true);
    if (validate()) {
      const dataForm = new FormData();

      dataForm.append("name", stateFormCreate.name);
      dataForm.append("image", imageFile);
      HANDLE_CREATE_CATEGORY.mutate(dataForm, {
        onSuccess: (data) => {
          if (!data?.errors) {
            setStateErrors({});
            setStateFormCreate({
              name: "",
            });
            setStateImg("");
            setImageFile(null);
            DataCateGory.refetch();

            alert("Create success");
          }
          setIsloadingSubmit(false);
        },
        onError: (err) => {
          console.log("err " + err);
          setIsloadingSubmit(false);
        },
      });
    } else {
      setIsloadingSubmit(false);
    }
  };
  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((selectedId) =>
      stateCategory
        .map((obj, index) => ({
          ...obj,
          id: obj._id, // Hoặc một giá trị id khác tùy ý bạn
        }))
        .find((row) => row.id === selectedId)
    );

    setSelectionModel(newSelectionModel);
  };
  const validate = () => {
    const newError = {};

    //validate required
    if (!stateFormCreate.name) {
      newError.name = "Vui lòng không để trống thông tin";
    }
    if (!stateImg) {
      newError.img = "Vui lòng không để trống thông tin";
    }

    setStateErrors(newError);

    return Object.keys(newError).length === 0;
  };

  const handleOpen = (data) => {
    const filter = stateCategory.filter((item, index) => {
      return item._id === data;
    });

    setModalData(filter[0]);
    setStateImgEDIT(filter[0]?.image);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const columnsShow = Columns(handleOpen);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setStateImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChangeEDIT = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFileEDIT(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setStateImgEDIT(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      height={"90vh"}
      sx={{
        overflowX: "hidden",

        overflowY: "auto",
      }}
      m="20px"
    >
      <Header title="Khởi tạo mục lục" subtitle="" />

      <Formik>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form
            // style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
            onSubmit={handleSubmit}
          >
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Tên mục"
                onBlur={handleBlur}
                onChange={handleChangeValue}
                value={stateFormCreate.name}
                error={stateErrors?.name || ""}
                helperText={stateErrors?.name || ""}
                name="name"
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box>
              <label
                htmlFor="file"
                style={{
                  marginTop: "1rem",
                  border: "1px solid #ccc",
                  display: "inline-block",
                  padding: "6px 12px",
                  cursor: "pointer",
                  color: "white",
                  borderRadius: "4px",
                }}
                className="custom-file-upload"
              >
                Chọn hình ảnh
              </label>
              {stateErrors?.img ? (
                <Typography color={"red"}>{stateErrors?.img}</Typography>
              ) : (
                ""
              )}
              <Input
                type="file"
                style={{ display: "none" }}
                id="file"
                name="images"
                accept="image/*"
                multiple
                max={4}
                onChange={handleFileChange}
              />
              <img
                style={{
                  marginRight: "5px",
                  marginTop: "10px",
                  borderRadius: "10px",
                  objectFit: "cover",
                  border: "solid 1px",
                  height: "200px",
                }}
                src={stateImg || ""}
                width={"100%"}
              ></img>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              {isLoading ? (
                <Button color="secondary" variant="contained">
                  <CircularProgress
                    sx={{ fontSize: "10px" }}
                  ></CircularProgress>
                </Button>
              ) : (
                <Button
                  onClick={handleCreateCategory}
                  color="secondary"
                  variant="contained"
                  sx={{
                    fontSize: "1rem",
                    // color: "white",
                    fontWeight: "600",
                  }}
                >
                  KHỞI TẠO
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "1.4rem",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "1.2rem",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          pagination
          rows={
            stateCategory
              ? stateCategory.map((item, index) => {
                  return {
                    ...item,
                    id: index,
                  };
                })
              : []
          }
          paginationMode="server"
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          columns={columnsShow}
          localeText={{
            toolbarFilters: "Chọn lọc",
            toolbarDensity: "Mật độ",
          }}
          loading={DataCateGory?.isFetching}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
        />{" "}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CloseModal handleclose={handleClose}></CloseModal>
            <Typography
              color={"black"}
              fontWeight={"600"}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Mục lục
            </Typography>
            <hr></hr>

            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box fullWidth>
                <lable style={{ color: "black" }}>Tên mục</lable>
                <input
                  fullWidth
                  variant="filled"
                  type="text"
                  label="name"
                  style={{ color: "black", padding: 10, marginLeft: "10px" }}
                  onChange={(e) => {
                    setModalData({
                      ...modalData,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  value={modalData?.name}
                  name="name"
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={"column"}
              >
                <label
                  htmlFor="fileEdit"
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    border: "1px solid #ccc",
                    display: "inline-block",
                    padding: "6px 12px",
                    cursor: "pointer",
                    color: "black",
                    borderRadius: "4px",
                  }}
                  className="custom-file-upload"
                >
                  Chọn hình ảnh
                </label>
                <Input
                  type="file"
                  style={{ display: "none" }}
                  id="fileEdit"
                  name="images"
                  accept="image/*"
                  multiple
                  max={4}
                  onChange={handleFileChangeEDIT}
                />
                <img src={stateImgEDIT || ""} width={200} height={150}></img>
              </Box>
              {IsloadingEdit ? (
                <Button color="warning" fullWidth variant="contained">
                  <CircularProgress></CircularProgress>
                </Button>
              ) : (
                <Button
                  color="warning"
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    handleUpdateCategory();
                  }}
                >
                  Điều chỉnh
                </Button>
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Categories;
