import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Typography,
  CircularProgress,
  useTheme,
  Breadcrumbs,
  Link,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";

import { GET_PURCHASE_DETAILS } from "../../apis/handleOrderDetails";
import { useState } from "react";
import { CustomToolbar } from "../../components/function";
import { Columns } from "./component/components";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Store } from "react-notifications-component";

import { notification_component } from "../../components/notifications";
import { Update_Purchase_Status } from "../../apis/handlePurchase";

import { CONFIRM_TRANSACTION } from "../../apis/handleTransaction";
const DETAILORDER = () => {
  const { purchase_id } = useParams();
  const [stateDetails, setStateDetails] = useState([]);
  const [statePurchase, setStatePurchase] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [Isloading, setIsloading] = useState({
    loading_DAGIAO: false,
    loading_DANGLAYHANG: false,
    loading_DANGVANCHUYEN: false,
    loading_DAHUY: false,
    loading_DACHOXACNHAN: false,
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const getRowClassName = (params) => {
    return selectionModel.includes(params.id) ? "red-bg" : "";
  };
  const Handle_Update_Purchase_Status = useMutation({
    mutationFn: (body) => {
      return Update_Purchase_Status(body);
    },
  });
  const HANDLE_CONFIRM_TRANSACTION = useMutation({
    mutationFn: (body) => {
      return CONFIRM_TRANSACTION(body);
    },
  });

  const DATA_GET_PURCHASE = useQuery({
    queryKey: ["GET_PURCHASE_DETAILS"],
    queryFn: async () => {
      const rs = await GET_PURCHASE_DETAILS(purchase_id);
      if (rs?.data?.data) {
        setStateDetails(rs?.data?.data);

        let filter = rs?.data?.data?.purchase_items.map((item, index) => {
          return {
            ...item,
            id: item?.product_id,
          };
        });
        setStatePurchase(filter);
      }

      return rs;
    },
  });
  const handleChangeStatus = async (status) => {
    if (purchase_id) {
      try {
        if (status === process.env.REACT_APP_WAIT_FOR_CONFIRMATION) {
          setIsloading({
            ...Isloading,
            loading_DACHOXACNHAN: true,
          });
        }
        if (status === process.env.REACT_APP_WAIT_FOR_GETTING) {
          setIsloading({
            ...Isloading,
            loading_DANGLAYHANG: true,
          });
        }
        if (status === process.env.REACT_APP_IN_PROGRESS) {
          setIsloading({
            ...Isloading,
            loading_DANGVANCHUYEN: true,
          });
        }
        if (status === process.env.REACT_APP_CANCELLED) {
          setIsloading({
            ...Isloading,
            loading_DAHUY: true,
          });
        }
        if (status === process.env.REACT_APP_DELIVERED) {
          setIsloading({
            ...Isloading,
            loading_DAGIAO: true,
          });
        }

        Handle_Update_Purchase_Status.mutate(
          {
            id: purchase_id,
            status: parseInt(status),
          },
          {
            onSuccess: (data) => {
              if (!data?.errorInfo) {
                Store.addNotification(
                  notification_component({
                    title: "Thao tác thành công",
                    message: "Chuyển trạng thái đơn thành công",
                    sta: "success",
                  })
                );
                DATA_GET_PURCHASE.refetch();
                setIsloading({
                  ...Isloading,
                  loading_DAGIAO: false,
                  loading_DANGLAYHANG: false,
                  loading_DANGVANCHUYEN: false,
                  loading_DAHUY: false,
                  loading_DACHOXACNHAN: false,
                });
                if (status === process.env.REACT_APP_DELIVERED) {
                  if (
                    stateDetails?.payment_method?._id ===
                    process.env.REACT_APP_PAYMENT_METHOD_CK
                  ) {
                    HANDLE_CONFIRM_TRANSACTION.mutate(
                      { code: stateDetails?.code_purchase },
                      {
                        onSuccess: (data) => {
                          console.log("success", data);
                        },
                        onError: (err) => {
                          console.log("err", err);
                        },
                      }
                    );
                  }
                }
              } else {
                Store.addNotification(
                  notification_component({
                    title: "Thất bại",
                    message: "Vui lòng kiểm tra trạng thái đơn hàng !!",
                    sta: "danger",
                  })
                );

                setIsloading({
                  ...Isloading,
                  loading_DAGIAO: false,
                  loading_DANGLAYHANG: false,
                  loading_DANGVANCHUYEN: false,
                  loading_DAHUY: false,
                  loading_DACHOXACNHAN: false,
                });
              }
            },
            onError: (err) => {
              Store.addNotification(
                notification_component({
                  title: "Có gì đó không đúng",
                  message: "Vui lòng thử lại",
                  sta: "danger",
                })
              );

              setIsloading({
                ...Isloading,
                loading_DAGIAO: false,
                loading_DANGLAYHANG: false,
                loading_DANGVANCHUYEN: false,
                loading_DAHUY: false,
                loading_DACHOXACNHAN: false,
              });
            },
          }
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_DAGIAO: false,
          loading_DANGLAYHANG: false,
          loading_DANGVANCHUYEN: false,
          loading_DAHUY: false,
          loading_DACHOXACNHAN: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };
  return (
    <>
      {" "}
      <Box p={3} className="header-details">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/#/transaction">
            Quản lý giao dịch
          </Link>

          <Typography color="text.primary">Chi tiết đơn hàng</Typography>
        </Breadcrumbs>
      </Box>
      {DATA_GET_PURCHASE.isFetching ? (
        <Typography fontSize={"1.5rem"} p={5}>
          Đang tải dữ liệu ...{" "}
        </Typography>
      ) : (
        <>
          {" "}
          {stateDetails?.code_purchase ? (
            <>
              {" "}
              <Box pl={5}>
                {" "}
                <Typography mb={1} variant="h5">
                  Bảng điều khiển
                </Typography>
                <ButtonGroup
                  variant="contained"
                  aria-label="Basic button group"
                >
                  {Isloading.loading_DAGIAO ? (
                    <Button sx={{ backgroundColor: "#008000" }}>
                      <LoginIcon sx={{ color: "white" }}></LoginIcon>
                      <Typography
                        ml={1}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        {" "}
                        <CircularProgress />
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handleChangeStatus(process.env.REACT_APP_DELIVERED);
                      }}
                      sx={{ backgroundColor: "#008000" }}
                    >
                      <LoginIcon sx={{ color: "white" }}></LoginIcon>
                      <Typography
                        ml={1}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        {" "}
                        Đã giao
                      </Typography>
                    </Button>
                  )}
                  {Isloading.loading_DACHOXACNHAN ? (
                    <Button sx={{ backgroundColor: "#9b9b61" }}>
                      <CancelScheduleSendIcon
                        sx={{ color: "white" }}
                      ></CancelScheduleSendIcon>
                      <Typography
                        ml={1}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        {" "}
                        <CircularProgress />
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handleChangeStatus(
                          process.env.REACT_APP_WAIT_FOR_CONFIRMATION
                        );
                      }}
                      sx={{ backgroundColor: "#9b9b61" }}
                    >
                      <CancelScheduleSendIcon
                        sx={{ color: "white" }}
                      ></CancelScheduleSendIcon>
                      <Typography
                        ml={1}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        {" "}
                        Đang chờ xác nhận
                      </Typography>
                    </Button>
                  )}
                  {Isloading.loading_DANGLAYHANG ? (
                    <Button sx={{ backgroundColor: "#00BFFF" }}>
                      <CancelScheduleSendIcon
                        sx={{ color: "white" }}
                      ></CancelScheduleSendIcon>
                      <Typography
                        ml={1}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        {" "}
                        <CircularProgress />
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handleChangeStatus(
                          process.env.REACT_APP_WAIT_FOR_GETTING
                        );
                      }}
                      sx={{ backgroundColor: "#00BFFF" }}
                    >
                      <CancelScheduleSendIcon
                        sx={{ color: "white" }}
                      ></CancelScheduleSendIcon>
                      <Typography
                        ml={1}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        {" "}
                        Đang được lấy hàng
                      </Typography>
                    </Button>
                  )}
                  {Isloading.loading_DANGVANCHUYEN ? (
                    <Button sx={{ backgroundColor: "#FFA500" }}>
                      <CancelScheduleSendIcon
                        sx={{ color: "white" }}
                      ></CancelScheduleSendIcon>
                      <Typography
                        ml={1}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        {" "}
                        <CircularProgress />
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handleChangeStatus(process.env.REACT_APP_IN_PROGRESS);
                      }}
                      sx={{ backgroundColor: "#FFA500" }}
                    >
                      <CancelScheduleSendIcon
                        sx={{ color: "white" }}
                      ></CancelScheduleSendIcon>
                      <Typography
                        ml={1}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        {" "}
                        Đang vận chuyển
                      </Typography>
                    </Button>
                  )}
                  {Isloading.loading_DAHUY ? (
                    <Button color="error">
                      <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
                      <Typography
                        ml={1}
                        sx={{ fontSize: "1rem", fontWeight: "700" }}
                      >
                        {" "}
                        <CircularProgress />
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        handleChangeStatus(process.env.REACT_APP_CANCELLED);
                      }}
                      color="error"
                    >
                      <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
                      <Typography
                        ml={1}
                        sx={{ fontSize: "1rem", fontWeight: "700" }}
                      >
                        {" "}
                        HUỶ ĐƠN
                      </Typography>
                    </Button>
                  )}
                </ButtonGroup>
              </Box>
              <Box p={3} pl={5}>
                <Typography mb={1} variant="h5">
                  {" "}
                  Trạng thái sản phẩm
                </Typography>

                {stateDetails?.status ===
                parseInt(process.env.REACT_APP_WAIT_FOR_CONFIRMATION) ? (
                  <Typography variant="h4" p={2} bgcolor={"#9b9b61"}>
                    ĐANG CHỜ XÁC NHẬN
                  </Typography>
                ) : stateDetails?.status ===
                  parseInt(process.env.REACT_APP_WAIT_FOR_GETTING) ? (
                  <Typography variant="h4" p={2} bgcolor={"#00BFFF"}>
                    ĐANG ĐƯỢC LẤY HÀNG
                  </Typography>
                ) : stateDetails?.status ===
                  parseInt(process.env.REACT_APP_IN_PROGRESS) ? (
                  <Typography variant="h4" p={2} bgcolor={"#FFA500"}>
                    ĐƠN ĐANG VẬN CHUYỂN
                  </Typography>
                ) : stateDetails?.status ===
                  parseInt(process.env.REACT_APP_DELIVERED) ? (
                  <Typography variant="h4" p={2} bgcolor={"#008000"}>
                    ĐÃ ĐƯỢC GIAO
                  </Typography>
                ) : (
                  <Typography variant="h4" p={2} bgcolor={"#FF0000"}>
                    ĐÃ HUỶ
                  </Typography>
                )}
              </Box>
              <Box
                className="row"
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "100px",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  className="col-sx-12 col-md-6"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#1f2a40",

                    overflowY: "scroll",
                    overflowX: "hidden",
                    gap: "10px",
                    flexDirection: "column",
                    border: "solid 3px",
                    padding: "16px",
                    fontSize: "1.2rem",
                  }}
                >
                  <Divider
                    sx={{ fontSize: "1.5rem", color: "#4CCEAC" }}
                    textAlign="left"
                  >
                    Thông tin đơn hàng
                  </Divider>
                  <Box>Mã giao dịch : {stateDetails?.code_purchase}</Box>
                  <Box>
                    Phương thức thanh toán :{" "}
                    {stateDetails?.payment_method?.name}
                  </Box>
                  <Box>
                    Người chịu phí vận chuyển :{" "}
                    {stateDetails?.cost_bearer?.name}
                  </Box>
                  <Box>
                    phí vận chuyển{": "}
                    {parseInt(stateDetails?.shipping_fee).toLocaleString(
                      "en-us"
                    )}{" "}
                    VND
                  </Box>

                  <Divider
                    sx={{ fontSize: "1.5rem", color: "#4CCEAC" }}
                    textAlign="left"
                  >
                    Giá trị đơn hàng
                  </Divider>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                      - Tổng giá gốc{" "}
                    </Typography>
                    {parseInt(
                      stateDetails?.purchase_total_price_original
                    ).toLocaleString("en-us")}{" "}
                    VND
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                      - Tổng giá trị đơn hàng{" "}
                    </Typography>
                    {parseInt(
                      stateDetails?.purchase_total_price
                    ).toLocaleString("en-us")}{" "}
                    VND
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                      - Phí giao hàng{" "}
                    </Typography>
                    {parseInt(stateDetails?.shipping_fee).toLocaleString(
                      "en-us"
                    )}{" "}
                    VND
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                      - Tổng số point{" "}
                    </Typography>
                    {parseInt(
                      stateDetails?.purchase_total_point
                    ).toLocaleString("en-us")}{" "}
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                      - Tổng số tiền cho point{" "}
                    </Typography>
                    {parseInt(
                      stateDetails?.purchase_total_price_points
                    ).toLocaleString("en-us")}{" "}
                    VND
                  </Box>

                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                      - Tổng lợi nhuận cho seller{" "}
                    </Typography>
                    {parseInt(
                      stateDetails?.purchase_total_profit_for_seller_and_collaborator
                    ).toLocaleString("en-us")}{" "}
                    VND
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                      - Tổng lợi nhuận cho admin{" "}
                    </Typography>
                    {parseInt(
                      stateDetails?.purchase_total_profit_for_admin
                    ).toLocaleString("en-us")}{" "}
                    VND
                  </Box>
                  <Divider
                    sx={{ fontSize: "1.5rem", color: "#4CCEAC" }}
                    textAlign="left"
                  >
                    Thông tin khách hàng
                  </Divider>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                      - Tên : {stateDetails?.customer?.name}
                    </Typography>
                  </Box>

                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                      - Số điện thoại : {stateDetails?.customer?.phone}
                    </Typography>
                  </Box>

                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"1.2rem"} color={"#e0e0e0"}>
                      - Địa chỉ : {stateDetails?.customer?.address}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className="col-sx-12 col-md-5"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#1f2a40",

                    overflowY: "scroll",
                    overflowX: "hidden",
                    gap: "10px",
                    flexDirection: "column",
                    border: "solid 3px",
                    padding: "16px",
                    fontSize: "1.2rem",
                  }}
                >
                  <div
                    style={{ width: "100%" }}
                    class="table-responsive col-sx-12 col-md-6"
                  >
                    <Divider
                      sx={{ fontSize: "1.5rem", color: "#4CCEAC" }}
                      textAlign="left"
                    >
                      Thông tin sản phẩm
                    </Divider>
                    <Box
                      m="40px 0 0 0"
                      height="75vh"
                      sx={{
                        "& .MuiDataGrid-root": {
                          border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontSize: "1.4rem",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                          fontSize: "1.2rem",
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                          color: `${colors.grey[100]} !important`,
                        },
                      }}
                    >
                      <DataGrid
                        pagination
                        rows={statePurchase}
                        columns={Columns}
                        localeText={{
                          toolbarFilters: "Chọn lọc",
                          toolbarDensity: "Mật độ",
                        }}
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        getRowClassName={getRowClassName}
                        components={{ Toolbar: CustomToolbar }}
                      />
                    </Box>
                  </div>
                </Box>
              </Box>
            </>
          ) : (
            <Typography fontSize={"1.5rem"} p={5}>
              Không tìm thấy đơn hàng. !
            </Typography>
          )}
        </>
      )}
    </>
  );
};
export default DETAILORDER;
