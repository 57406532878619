import TouchAppIcon from "@mui/icons-material/TouchApp";
import { Box, FormControl, Typography } from "@mui/material";
import {
  CaseDatetimeToYYYYMMDD,
  CaseDatetimeToYYYYMMDDHHMMSS,
} from "../../../components/function";
import "../style.css";
import styled from "styled-components";
const StyledSelect = styled.select`
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f3f6f9;
  color: #333;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const StyledOption = styled.option`
  padding: 8px;
  background-color: #fff;
  color: #333;
  font-size: 16px;

  &:hover {
    background-color: #f3f6f9;
  }
`;
export const UnstyledSelectIntroduction = ({
  stateStatus,
  setStateStatus,
  handleRefreshData,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateStatus(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateStatus}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={""}>Lấy tất cả sản phẩm</StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_WAIT_FOR_CONFIRMATION)}
        >
          Đang chờ xác nhận
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_WAIT_FOR_GETTING)}>
          Đang được lấy hàng
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_IN_PROGRESS)}>
          Đang được vận chuyển
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_DELIVERED)}>
          Đã giao
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_CANCELLED)}>
          Đã huỷ
        </StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const SelectOldNewOrder = ({
  setStateSortDate,
  stateSortDate,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateSortDate(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateSortDate}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={"latest"}>Mới nhất</StyledOption>
        <StyledOption value={"oldest"}>Cũ nhất</StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
};

export const columns = ({ handleOpen, setStateCodeDH }) => [
  {
    field: "code_purchase",
    width: 200,
    headerName: "Mã đơn hàng",
    renderCell: (params) => (
      <Box display="flex" alignItems="center">
        {params?.row?.payment_method?._id ===
        process.env.REACT_APP_PAYMENT_METHOD_CK ? (
          <Typography fontWeight={500}>{params?.value}</Typography>
        ) : (
          <a
            style={{
              fontWeight: "700",
              textDecoration: "none",
              color: "#1abc9c",
            }}
            href={`/#/chi-tiet-don-hang/${params.row.id}`}
          >
            {params?.value}
          </a>
        )}
      </Box>
    ),
  },
  {
    field: "seller_info",
    headerName: "Người lên đơn",
    width: 200,
    renderCell: (params) => (
      <Typography fontSize="1rem" fontWeight={500} color="#2980b9">
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "created_at",
    headerName: "Thời điểm",
    width: 200,
    renderCell: (params) => (
      <Typography fontSize="1rem" color="#7f8c8d">
        {CaseDatetimeToYYYYMMDDHHMMSS(params?.value)}
      </Typography>
    ),
  },
  {
    field: "purchase_total_price",
    headerName: "Tổng giá trị đơn hàng",
    width: 200,
    renderCell: (params) => (
      <Typography fontWeight={600} color="#27ae60">
        {parseInt(params?.value).toLocaleString("en-US")} VND
      </Typography>
    ),
  },
  {
    field: "purchase_total_profit_final_for_seller",
    headerName: "Lợi nhuận cá nhân người bán",
    width: 200,
    renderCell: (params) => (
      <Typography fontWeight={600} color="#2ecc71">
        {parseInt(params?.value).toLocaleString("en-US")} VND
      </Typography>
    ),
  },
  {
    field: "purchase_total_profit_final_for_seller",
    headerName: "Tổng lợi nhuận cho Seller",
    width: 200,
    renderCell: (params) => (
      <Typography fontWeight={600} color="#3498db">
        {parseInt(params?.value).toLocaleString("en-US")} VND
      </Typography>
    ),
  },
  {
    field: "purchase_total_profit_for_admin",
    headerName: "Tổng lợi nhuận cho admin",
    width: 200,
    renderCell: (params) => (
      <Typography fontWeight={600} color="#e74c3c">
        {parseInt(params?.value).toLocaleString("en-US")} VND
      </Typography>
    ),
  },
  {
    field: "payment_method",
    headerName: "Phương thức thanh toán",
    width: 350,
    renderCell: (params) => (
      <Typography
        fontSize="1.1rem"
        fontWeight={600}
        color={
          params?.value?._id === process.env.REACT_APP_PAYMENT_METHOD_CK
            ? "#27ae60"
            : "#1abc9c"
        }
      >
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "cost_bearer",
    headerName: "Người chịu phí vận chuyển",
    width: 250,
    renderCell: (params) => (
      <Typography
        fontSize="1.1rem"
        fontWeight={600}
        color={
          params?.value?._id === process.env.REACT_APP_COST_BEARER_NB
            ? "#FF5733"
            : "#00ff51"
        }
      >
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "purchase_items",
    headerName: "Số lượng sản phẩm",
    width: 200,
    renderCell: (params) => (
      <button
        onClick={() => {
          setStateCodeDH(params?.row?.code_purchase);
          handleOpen(params?.value);
        }}
        style={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#f0f0f0",
          border: "1px solid #ddd",
          fontWeight: 600,
        }}
      >
        <TouchAppIcon style={{ marginRight: "5px", color: "#2980b9" }} />
        {params?.value?.length} Sản phẩm
      </button>
    ),
  },
  {
    field: "status",
    headerName: "Trạng thái",
    width: 300,
    cellClassName: "column-sticky",
    renderCell: (params) => {
      const statusColorMap = {
        [process.env.REACT_APP_WAIT_FOR_CONFIRMATION]: "#9b9b61",
        [process.env.REACT_APP_WAIT_FOR_GETTING]: "#00BFFF",
        [process.env.REACT_APP_IN_PROGRESS]: "#FFA500",
        [process.env.REACT_APP_DELIVERED]: "#008000",
      };
      const defaultColor = "#FF0000";
      const bgColor = statusColorMap[params?.value] || defaultColor;

      const statusText =
        {
          [process.env.REACT_APP_WAIT_FOR_CONFIRMATION]: "ĐANG CHỜ XÁC NHẬN",
          [process.env.REACT_APP_WAIT_FOR_GETTING]: "ĐANG ĐƯỢC LẤY HÀNG",
          [process.env.REACT_APP_IN_PROGRESS]: "ĐƠN ĐANG VẬN CHUYỂN",
          [process.env.REACT_APP_DELIVERED]: "ĐÃ ĐƯỢC GIAO",
        }[params?.value] || "ĐÃ HUỶ";

      return (
        <Typography variant="h4" p={2} bgcolor={bgColor} color="white">
          {statusText}
        </Typography>
      );
    },
  },
  {
    field: "purchase_total_point",
    headerName: "Tổng số điểm (POINT)",
    width: 200,
    renderCell: (params) => (
      <Typography fontWeight={600} color="#8e44ad">
        {parseInt(params?.value).toLocaleString("en-US")}
      </Typography>
    ),
  },
  {
    field: "customer",
    headerName: "Thông tin khách hàng",
    width: 1200, // Mở rộng chiều rộng của cột này

    renderCell: (params) => (
      <Box display={"flex"} gap={1} justifyContent={"space-between"}>
        <Typography fontWeight={500}>
          Tên khách hàng: {params?.value?.name}
        </Typography>
        <Typography>Số điện thoại: {params?.value?.phone}</Typography>
        <Typography>Địa chỉ: {params?.value?.address}</Typography>
      </Box>
    ),
  },
];

export const columnsPDP = (handleOpen) => [
  {
    field: "code_purchase",
    width: 150,
    headerName: "Mã đơn hàng",
    renderCell: (params) => (
      <span style={{ color: "blanchedalmond" }}>{params?.value}</span>
    ),
  },

  {
    field: "seller_info",
    headerName: "Người lên đơn",
    width: 200,

    renderCell: (params) => <span>{params?.value.name}</span>,
  },

  {
    field: "purchase_total_price",
    headerName: "Tổng giá trị đơn hàng",
    width: 200,

    renderCell: (params) => (
      <span>{parseInt(params?.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "created_at",
    headerName: "Thời điểm",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <span>{CaseDatetimeToYYYYMMDD(params?.value)}</span>
        </>
      );
    },
  },
  {
    field: "payment_method",
    headerName: "Phương thức thanh toán",
    width: 350,
    renderCell: (params) => (
      <Typography
        fontSize={"1.1rem"}
        fontWeight={600}
        color={
          // Thanh toán nhận hàng
          params?.value?._id === process.env.REACT_APP_PAYMENT_METHOD_CK
            ? "green"
            : "#6ff7ff"
        }
      >
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "cost_bearer",
    headerName: "Người chịu phí vận chuyển",
    width: 250,
    renderCell: (params) => (
      <Typography
        fontSize={"1.1rem"}
        fontWeight={600}
        color={
          // người bán
          params?.value?._id === process.env.REACT_APP_COST_BEARER_NB
            ? "#FF5733"
            : "#00ff51"
        }
      >
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "purchase_items",
    headerName: "Số lượng sản phẩm",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <button
            onClick={() => handleOpen(params?.value)}
            style={{ padding: "10px", borderRadius: "10px", fontWeight: 600 }}
          >
            <TouchAppIcon></TouchAppIcon>
            {params.value.length} Sản phẩm
          </button>
        </>
      );
    },
  },

  {
    field: "customer",
    headerName: "Thông tin khách hàng",
    width: 1000,
    renderCell: (params) => (
      <>
        <Box color={"#ffc5f2"} display={"flex"}>
          <Typography fontSize={"1rem"}>id : {params?.value?.phone}</Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>Tên : {params?.value?.name}</Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>
            Email : {params?.value?.address}
          </Typography>
        </Box>
      </>
    ),
  },
  // {
  //   field: "product_images",
  //   headerName: "Hình ảnh sản phẩm",
  //   width: 200,

  //   renderCell: (params) => (
  //     <div>
  //       {params?.value[0]?.path ? (
  //         <img
  //           src={params.value[0].path}
  //           alt="Hình ảnh"
  //           style={{ width: "100px", height: "auto", objectFit: "cover" }}
  //         />
  //       ) : (
  //         "No Images"
  //       )}
  //     </div>
  //   ),
  // },
];

export const columnsInviteSeller = [
  {
    field: "seller_info",
    width: 350,
    headerName: "Thông tin tài khoản",
    renderCell: (params) => (
      <span style={{ color: "blanchedalmond" }}>
        Tên: {params?.value?.name} , sđt: {params?.value?.phone}
      </span>
    ),
  },
  {
    field: "package_info",
    headerName: "Thông tin gói",
    width: 350,
    renderCell: (params) => {
      return (
        <>
          <span style={{ color: "lightblue" }}>
            Tên gói: {params?.value?.name} , thời hạn:{" "}
            {params?.value?.duration_in_months}
          </span>
        </>
      );
    },
  },
  {
    field: "created_at",
    headerName: "Thời điểm",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <span>{CaseDatetimeToYYYYMMDD(params?.value)}</span>
        </>
      );
    },
  },
];

export const columnsSELLER = (handleOpen) => [
  {
    field: "code_purchase",
    width: 150,
    headerName: "Mã đơn hàng",
    renderCell: (params) => (
      <span style={{ color: "blanchedalmond" }}>{params?.value}</span>
    ),
  },

  {
    field: "seller_info",
    headerName: "Người lên đơn",
    width: 200,

    renderCell: (params) => <span>{params?.value.name}</span>,
  },

  {
    field: "purchase_total_price",
    headerName: "Tổng giá trị đơn hàng",
    width: 200,

    renderCell: (params) => (
      <span>{parseInt(params?.value).toLocaleString("en-US")}</span>
    ),
  },

  {
    field: "purchase_total_profit_final_for_seller",
    headerName: "Lợi nhuận seller",
    width: 200,

    renderCell: (params) => (
      <span>{parseInt(params?.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "created_at",
    headerName: "Thời điểm",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <span>{CaseDatetimeToYYYYMMDD(params?.value)}</span>
        </>
      );
    },
  },
  {
    field: "payment_method",
    headerName: "Phương thức thanh toán",
    width: 350,
    renderCell: (params) => (
      <Typography
        fontSize={"1.1rem"}
        fontWeight={600}
        color={
          // Thanh toán nhận hàng
          params?.value?._id === process.env.REACT_APP_PAYMENT_METHOD_CK
            ? "green"
            : "#6ff7ff"
        }
      >
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "cost_bearer",
    headerName: "Người chịu phí vận chuyển",
    width: 250,
    renderCell: (params) => (
      <Typography
        fontSize={"1.1rem"}
        fontWeight={600}
        color={
          // người bán
          params?.value?._id === process.env.REACT_APP_COST_BEARER_NB
            ? "#FF5733"
            : "#00ff51"
        }
      >
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "purchase_items",
    headerName: "Số lượng sản phẩm",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <button
            onClick={() => handleOpen(params?.value)}
            style={{ padding: "10px", borderRadius: "10px", fontWeight: 600 }}
          >
            <TouchAppIcon></TouchAppIcon>
            {params.value.length} Sản phẩm
          </button>
        </>
      );
    },
  },

  {
    field: "customer",
    headerName: "Thông tin khách hàng",
    width: 1000,
    renderCell: (params) => (
      <>
        <Box color={"#ffc5f2"} display={"flex"}>
          <Typography fontSize={"1rem"}>id : {params?.value?.phone}</Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>Tên : {params?.value?.name}</Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>
            Email : {params?.value?.address}
          </Typography>
        </Box>
      </>
    ),
  },
  // {
  //   field: "product_images",
  //   headerName: "Hình ảnh sản phẩm",
  //   width: 200,

  //   renderCell: (params) => (
  //     <div>
  //       {params?.value[0]?.path ? (
  //         <img
  //           src={params.value[0].path}
  //           alt="Hình ảnh"
  //           style={{ width: "100px", height: "auto", objectFit: "cover" }}
  //         />
  //       ) : (
  //         "No Images"
  //       )}
  //     </div>
  //   ),
  // },
];
export const columnsSELLERCTV = (handleOpen) => [
  {
    field: "code_purchase",
    width: 150,
    headerName: "Mã đơn hàng",
    renderCell: (params) => (
      <span style={{ color: "blanchedalmond" }}>{params?.value}</span>
    ),
  },

  {
    field: "seller_info",
    headerName: "Người lên đơn",
    width: 200,

    renderCell: (params) => <span>{params?.value.name}</span>,
  },

  {
    field: "purchase_total_price",
    headerName: "Tổng giá trị đơn hàng",
    width: 200,

    renderCell: (params) => (
      <span>{parseInt(params?.value).toLocaleString("en-US")}</span>
    ),
  },

  {
    field: "purchase_total_profit_for_seller",
    headerName: "Lợi nhuận seller",
    width: 200,

    renderCell: (params) => (
      <span>{parseInt(params?.value).toLocaleString("en-US")}</span>
    ),
  },
  {
    field: "created_at",
    headerName: "Thời điểm",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <span>{CaseDatetimeToYYYYMMDD(params?.value)}</span>
        </>
      );
    },
  },
  {
    field: "payment_method",
    headerName: "Phương thức thanh toán",
    width: 350,
    renderCell: (params) => (
      <Typography
        fontSize={"1.1rem"}
        fontWeight={600}
        color={
          // Thanh toán nhận hàng
          params?.value?._id === process.env.REACT_APP_PAYMENT_METHOD_CK
            ? "green"
            : "#6ff7ff"
        }
      >
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "cost_bearer",
    headerName: "Người chịu phí vận chuyển",
    width: 250,
    renderCell: (params) => (
      <Typography
        fontSize={"1.1rem"}
        fontWeight={600}
        color={
          // người bán
          params?.value?._id === process.env.REACT_APP_COST_BEARER_NB
            ? "#FF5733"
            : "#00ff51"
        }
      >
        {params?.value?.name}
      </Typography>
    ),
  },
  {
    field: "purchase_items",
    headerName: "Số lượng sản phẩm",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <button
            onClick={() => handleOpen(params?.value)}
            style={{ padding: "10px", borderRadius: "10px", fontWeight: 600 }}
          >
            <TouchAppIcon></TouchAppIcon>
            {params.value.length} Sản phẩm
          </button>
        </>
      );
    },
  },

  {
    field: "customer",
    headerName: "Thông tin khách hàng",
    width: 1000,
    renderCell: (params) => (
      <>
        <Box color={"#ffc5f2"} display={"flex"}>
          <Typography fontSize={"1rem"}>id : {params?.value?.phone}</Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>Tên : {params?.value?.name}</Typography>
          &emsp;
          {" - "}
          &emsp;
          <Typography fontSize={"1rem"}>
            Email : {params?.value?.address}
          </Typography>
        </Box>
      </>
    ),
  },
  // {
  //   field: "product_images",
  //   headerName: "Hình ảnh sản phẩm",
  //   width: 200,

  //   renderCell: (params) => (
  //     <div>
  //       {params?.value[0]?.path ? (
  //         <img
  //           src={params.value[0].path}
  //           alt="Hình ảnh"
  //           style={{ width: "100px", height: "auto", objectFit: "cover" }}
  //         />
  //       ) : (
  //         "No Images"
  //       )}
  //     </div>
  //   ),
  // },
];
