import {
  Box,
  ButtonGroup,
  CircularProgress,
  Typography,
  useTheme,
  Button,
  Autocomplete,
  TextField,
  Modal,
  Divider,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import { useState } from "react";
import LoginIcon from "@mui/icons-material/Login";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import {
  GET_ALL_PURCHASE,
  Get_Purchase_By_PDP,
  Get_Purchase_By_SELLER,
  Handle_Search_Get_Purchase_Admin,
} from "../../apis/handlePurchase";
import { notification_component } from "../../components/notifications";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  columns,
  style,
  columnsPDP,
  SelectOldNewOrder,
  UnstyledSelectIntroduction,
} from "./component/components";
import { Update_Purchase_Status } from "../../apis/handlePurchase";
import { Store } from "react-notifications-component";

import "./style.css";
import {
  notification_status_accept,
  notification_Error,
} from "../product/components/components";
import { CloseModal, CustomToolbar } from "../../components/function";

import StatisticOrder from "./StatisticOrder";

import {
  CustomNoRowsOverlay,
  SearchComponent,
} from "../../components/components";
const Team = ({ data }) => {
  const [stateCodeDH, setStateCodeDH] = useState("");
  const [statePage, setStatePage] = useState(0);
  const [stateLimit, setStateLimit] = useState(25);
  const [statePurchase, setStatePurchase] = useState([]);
  const [statePurchaseSearch, setStatePurchaseSearch] = useState({});

  const [stateSort, setStateSort] = useState("asc");
  const [stateSortDate, setStateSortDate] = useState("latest");
  const [stateCodePurchaseSearch, setStateCodePurchaseSearch] = useState("");
  const [stateStatus, setStateStatus] = useState("");

  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [dateF, onChangeF] = useState(() => {
    const currentDate = new Date();
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); // Ngày đầu của tháng hiện tại
  });
  const [dateT, onChangeT] = useState(new Date());

  const [Isloading, setIsloading] = useState({
    loading_DAGIAO: false,
    loading_DANGLAYHANG: false,
    loading_DANGVANCHUYEN: false,
    loading_DAHUY: false,
    loading_DACHOXACNHAN: false,
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const getRowClassName = (params) => {
    return selectionModel.includes(params.id) ? "red-bg" : "";
  };

  const DATA_GET_ALL_PURCHASE = useQuery({
    queryKey: [
      "GET_ALL_PURCHASE_SEARCH",
      statePage,
      stateStatus,
      stateCodePurchaseSearch,
      stateSortDate,
    ],
    queryFn: async () => {
      const res = await GET_ALL_PURCHASE({
        page: statePage + 1,
        limit: stateLimit,

        sort: stateSort,
        status: stateStatus,
        code_purchase: stateCodePurchaseSearch,
        date: stateSortDate,
      });
      if (res?.data?.data) {
        let newArray = res?.data?.data.purchases.map((item, index) => {
          return {
            ...item,
            id: item._id,
          };
        });

        setStatePurchase(newArray);
        setRowCount(parseInt(res?.data?.data?.total_purchase));
      }
      return res;
    },
  });
  const HANDLE_SEARCH_GET_PURCHASE = useMutation({
    mutationFn: (body) => {
      return Handle_Search_Get_Purchase_Admin(body);
    },
  });
  const HANDLE_GET_DATA_PURCHASE_PDP = useMutation({
    mutationFn: (body) => {
      return Get_Purchase_By_PDP(body);
    },
  });

  const Handle_Update_Purchase_Status = useMutation({
    mutationFn: (body) => {
      return Update_Purchase_Status(body);
    },
  });

  const handleChangeStatus = async (status) => {
    if (selectedRow.length === 0) {
      alert("Vui lòng chọn sản phẩm");
    } else {
      try {
        if (status === process.env.REACT_APP_WAIT_FOR_CONFIRMATION) {
          setIsloading({
            ...Isloading,
            loading_DACHOXACNHAN: true,
          });
        }
        if (status === process.env.REACT_APP_WAIT_FOR_GETTING) {
          setIsloading({
            ...Isloading,
            loading_DANGLAYHANG: true,
          });
        }
        if (status === process.env.REACT_APP_IN_PROGRESS) {
          setIsloading({
            ...Isloading,
            loading_DANGVANCHUYEN: true,
          });
        }
        if (status === process.env.REACT_APP_CANCELLED) {
          setIsloading({
            ...Isloading,
            loading_DAHUY: true,
          });
        }
        if (status === process.env.REACT_APP_DELIVERED) {
          setIsloading({
            ...Isloading,
            loading_DAGIAO: true,
          });
        }

        await Promise.all(
          selectedRow.map((body) =>
            Handle_Update_Purchase_Status.mutate(
              {
                id: body._id,
                status: parseInt(status),
              },
              {
                onSuccess: (data) => {
                  if (data?.errorInfo) {
                    Store.addNotification(
                      notification_component({
                        title: "Thao tác thất bại !",
                        message: "Vui lòng kiểm tra lại trạng thái đơn hàng",
                        sta: "danger",
                      })
                    );
                    setIsloading({
                      ...Isloading,
                      loading_DAGIAO: false,
                      loading_DANGLAYHANG: false,
                      loading_DANGVANCHUYEN: false,
                      loading_DAHUY: false,
                      loading_DACHOXACNHAN: false,
                    });
                  } else {
                    Store.addNotification(notification_status_accept);
                    DATA_GET_ALL_PURCHASE.refetch();
                    // setSelectedRow([]);
                    // setSelectionModel([]);
                    setIsloading({
                      ...Isloading,
                      loading_DAGIAO: false,
                      loading_DANGLAYHANG: false,
                      loading_DANGVANCHUYEN: false,
                      loading_DAHUY: false,
                      loading_DACHOXACNHAN: false,
                    });
                  }
                },
                onError: (err) => {
                  Store.addNotification(notification_Error);
                  setIsloading({
                    ...Isloading,
                    loading_DAGIAO: false,
                    loading_DANGLAYHANG: false,
                    loading_DANGVANCHUYEN: false,
                    loading_DAHUY: false,
                    loading_DACHOXACNHAN: false,
                  });
                },
              }
            )
          )
        );
      } catch (error) {
        setIsloading({
          ...Isloading,
          loading_DAGIAO: false,
          loading_DANGLAYHANG: false,
          loading_DANGVANCHUYEN: false,
          loading_DAHUY: false,
          loading_DACHOXACNHAN: false,
        });
        console.error("Error creating users:", error);
      }
    }
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((selectedId) =>
      statePurchase.find((row) => row.id === selectedId)
    );
    const hasAcceptedOrCancelled = newSelectionModel.some((selectedId) => {
      const selectedRow = selectedRows.find((row) => row.id === selectedId);
      return (
        selectedRow &&
        selectedRow.payment_method._id ===
          process.env.REACT_APP_PAYMENT_METHOD_CK
      );
    });

    if (hasAcceptedOrCancelled) {
      // Một trong những phần tử có status là "ACCEPT" hoặc "CANCEL"
      alert("Không thể thao tác đơn hàng chuyển khoản !");
      return;
    }
    setSelectedRow(selectedRows);

    setSelectionModel(newSelectionModel);
  };
  const handleSearch = () => {
    if (dateF && dateT) {
      let Form = {
        startTime: dateF,
        endTime: dateT,
      };
      HANDLE_SEARCH_GET_PURCHASE.mutate(Form, {
        onSuccess: (data) => {
          if (data?.data?.purchases) {
            setStatePurchaseSearch(data?.data);

            let newArray = data?.data?.purchases.map((item, index) => {
              return {
                ...item,
                id: item._id,
              };
            });

            setStatePurchase(newArray);
            setRowCount(newArray.length && 0);
          } else {
            setStatePurchase([]);
            setRowCount(0);
          }
        },
      });
    } else {
      alert("Vui lòng chọn ngày bắt đầu và kết thúc!");
    }
  };
  const handleOpen = (data) => {
    setModalData(data);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const printTable = () => {
    // Lấy bảng gốc
    const table = document.getElementById("printableTable");
    // Tạo một bảng mới chỉ chứa các cột cần in
    const newTable = document.createElement("table");
    newTable.border = "1";

    // Sao chép tiêu đề của các cột cần in
    const header = table.rows[0];
    const newHeader = newTable.insertRow();
    newHeader.insertCell().innerText = header.cells[0].innerText;
    newHeader.insertCell().innerText = header.cells[1].innerText;
    newHeader.insertCell().innerText = header.cells[2].innerText;

    // Sao chép các hàng và cột cần in
    for (let i = 1; i < table.rows.length; i++) {
      const row = table.rows[i];
      const newRow = newTable.insertRow();
      newRow.insertCell().innerText = row.cells[0].innerText;
      newRow.insertCell().innerText = row.cells[1].innerText;
      newRow.insertCell().innerText = row.cells[2].innerText;
    }

    // Tạo cửa sổ mới để in bảng mới
    const newWindow = window.open("", "", "width=800,height=600");
    newWindow.document.write(
      `<html><head><title>Mã đơn hàng : ${stateCodeDH}</title>`
    );
    newWindow.document.write(
      "<style>table { width: 100%; border-collapse: collapse; } table, th, td { border: 1px solid black; } th, td { padding: 8px; text-align: left; }</style>"
    );
    newWindow.document.write("</head><body >");
    newWindow.document.write(newTable.outerHTML);
    newWindow.document.write("</body></html>");
    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
    setStateCodeDH("");
  };
  const columnsShow = columns({
    handleOpen: handleOpen,
    setStateCodeDH: setStateCodeDH,
  });

  return (
    <Box
      m="20px"
      height={"90vh"}
      sx={{
        overflowX: "hidden",

        overflowY: "auto",
      }}
    >
      <Header title="Đơn hàng" />
      <Typography mb={1} variant="h5">
        Bảng điều khiển
      </Typography>
      <ButtonGroup variant="contained" aria-label="Basic button group">
        {Isloading.loading_DAGIAO ? (
          <Button sx={{ backgroundColor: "#008000" }}>
            <LoginIcon sx={{ color: "white" }}></LoginIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              <CircularProgress />
            </Typography>
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleChangeStatus(process.env.REACT_APP_DELIVERED);
            }}
            sx={{ backgroundColor: "#008000" }}
          >
            <LoginIcon sx={{ color: "white" }}></LoginIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              Đã giao
            </Typography>
          </Button>
        )}
        {Isloading.loading_DACHOXACNHAN ? (
          <Button sx={{ backgroundColor: "#9b9b61" }}>
            <CancelScheduleSendIcon
              sx={{ color: "white" }}
            ></CancelScheduleSendIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              <CircularProgress />
            </Typography>
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleChangeStatus(process.env.REACT_APP_WAIT_FOR_CONFIRMATION);
            }}
            sx={{ backgroundColor: "#9b9b61" }}
          >
            <CancelScheduleSendIcon
              sx={{ color: "white" }}
            ></CancelScheduleSendIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              Đang chờ xác nhận
            </Typography>
          </Button>
        )}
        {Isloading.loading_DANGLAYHANG ? (
          <Button sx={{ backgroundColor: "#00BFFF" }}>
            <CancelScheduleSendIcon
              sx={{ color: "white" }}
            ></CancelScheduleSendIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              <CircularProgress />
            </Typography>
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleChangeStatus(process.env.REACT_APP_WAIT_FOR_GETTING);
            }}
            sx={{ backgroundColor: "#00BFFF" }}
          >
            <CancelScheduleSendIcon
              sx={{ color: "white" }}
            ></CancelScheduleSendIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              Đang được lấy hàng
            </Typography>
          </Button>
        )}
        {Isloading.loading_DANGVANCHUYEN ? (
          <Button sx={{ backgroundColor: "#FFA500" }}>
            <CancelScheduleSendIcon
              sx={{ color: "white" }}
            ></CancelScheduleSendIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              <CircularProgress />
            </Typography>
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleChangeStatus(process.env.REACT_APP_IN_PROGRESS);
            }}
            sx={{ backgroundColor: "#FFA500" }}
          >
            <CancelScheduleSendIcon
              sx={{ color: "white" }}
            ></CancelScheduleSendIcon>
            <Typography
              ml={1}
              sx={{ fontSize: "1rem", fontWeight: "700", color: "white" }}
            >
              {" "}
              Đang vận chuyển
            </Typography>
          </Button>
        )}
        {Isloading.loading_DAHUY ? (
          <Button color="error">
            <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
            <Typography ml={1} sx={{ fontSize: "1rem", fontWeight: "700" }}>
              {" "}
              <CircularProgress />
            </Typography>
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleChangeStatus(process.env.REACT_APP_CANCELLED);
            }}
            color="error"
          >
            <DoNotDisturbAltIcon></DoNotDisturbAltIcon>
            <Typography ml={1} sx={{ fontSize: "1rem", fontWeight: "700" }}>
              {" "}
              HUỶ ĐƠN
            </Typography>
          </Button>
        )}
      </ButtonGroup>
      <Box mt={2} className="col-5">
        <SearchComponent
          value={stateCodePurchaseSearch}
          setValue={setStateCodePurchaseSearch}
          placeholder={"Tìm kiếm Mã đơn hàng...."}
        ></SearchComponent>
      </Box>
      <Stack
        direction="row"
        width={"50%"}
        // divider={<Divider orientation="vertical" flexItem />}
        justifyContent={"space-between"}
      >
        <Box
          p={2}
          flex={1}
          textAlign={"center"}
          borderRight={"solid 1px #f7f7f7"}
          paddingLeft={"0px"}
        >
          <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
            Chọn lọc
          </Typography>
          <SelectOldNewOrder
            setStatePage={setStatePage}
            setStateSortDate={setStateSortDate}
            stateSortDate={stateSortDate}
          ></SelectOldNewOrder>
        </Box>
        <Box p={2} flex={1} textAlign={"center"}>
          {" "}
          <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
            Tình Trạng
          </Typography>
          <Typography fontWeight={600}>
            <UnstyledSelectIntroduction
              stateStatus={stateStatus}
              setStatePage={setStatePage}
              setStateStatus={setStateStatus}
              // handleRefreshData={handleRefreshData}
            ></UnstyledSelectIntroduction>
          </Typography>
        </Box>
      </Stack>
      <Box
        m="40px 20px 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "1.1rem",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "1rem",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          pagination
          page={statePage}
          pageSize={stateLimit}
          rowCount={rowCount}
          paginationMode="server"
          checkboxSelection
          rows={statePurchase || []}
          columns={columnsShow}
          onPageChange={(e) => {
            setStatePage(e);
          }}
          onPageSizeChange={(e) => {
            setStateLimit(e);
          }}
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          localeText={{
            toolbarFilters: "Chọn lọc",
            toolbarDensity: "Mật độ",
          }}
          loading={DATA_GET_ALL_PURCHASE?.isFetching}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          getRowClassName={getRowClassName}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseModal handleclose={handleClose}></CloseModal>
          <Typography
            color={"black"}
            fontWeight={"600"}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Sản phẩm có trong đơn
          </Typography>
          <Button onClick={printTable} variant="contained">
            In dữ liệu
          </Button>
          <hr></hr>

          <div class="table-responsive">
            <table id="printableTable" class="table table-primary">
              <thead>
                <tr>
                  <th scope="col">Mã sản phẩm</th>
                  <th scope="col">Tên sản phẩm</th>
                  <th scope="col">Số lượng bán</th>

                  <th scope="col">Tổng lợi nhuận cho seller</th>
                  <th scope="col">Tổng lợi nhuận cho sàn</th>
                  <th scope="col">Tổng lợi nhuận cho nhà cung cấp</th>
                </tr>
              </thead>
              <tbody>
                {modalData &&
                  modalData.map((item, index) => (
                    <tr class="">
                      <td>{item?.code}</td>
                      <td>{item?.product_name}</td>
                      <td>{item?.buy_count}</td>
                      <td>
                        {parseInt(item?.total_profit_for_seller).toLocaleString(
                          "it-IT",
                          {
                            style: "currency",
                            currency: "VND",
                          }
                        )}
                      </td>
                      <td>
                        {parseInt(item?.total_profit_for_admin).toLocaleString(
                          "it-IT",
                          {
                            style: "currency",
                            currency: "VND",
                          }
                        )}
                      </td>
                      <td>
                        {parseInt(item?.total_profit_for_pdp).toLocaleString(
                          "it-IT",
                          {
                            style: "currency",
                            currency: "VND",
                          }
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Box>
      </Modal>
      <Box mt={5}>
        <Divider>Thống kê doanh thu</Divider>
        <StatisticOrder></StatisticOrder>
      </Box>
    </Box>
  );
};

export default Team;
