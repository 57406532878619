import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  Typography,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ColorModeContext, tokens } from "../theme";
import { useContext } from "react";
import { GridNoRowsOverlay } from "@mui/x-data-grid";

export const SearchComponent = ({ placeholder, value, setValue }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box
      display="flex"
      backgroundColor={colors.primary[400]}
      borderRadius="3px"
    >
      <InputBase
        sx={{ ml: 2, flex: 1 }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={placeholder}
      />
      <IconButton type="button" sx={{ p: 1 }}>
        <SearchIcon />
      </IconButton>
    </Box>
  );
};

export const CustomNoRowsOverlay = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h6" color="#555" fontWeight={600}>
        Không có dữ liệu để hiển thị
      </Typography>
      <Typography color="#888">
        Vui lòng kiểm tra lại hoặc thêm dữ liệu mới.
      </Typography>
    </Box>
  );
};

export const CustomLoadingOverlay = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        zIndex: 100,
        flexDirection: "column",
        backgroundColor: "rgba(255, 255, 255, 0.7)", // Đảm bảo nhìn thấy loading trên bảng
      }}
    >
      <CircularProgress /> {/* Spinner */}
      <Typography variant="body2" sx={{ mt: 1 }}>
        Đang tải dữ liệu...
      </Typography>
    </Box>
  );
};
