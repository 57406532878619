import { Box, Typography, FormControl } from "@mui/material";
import { CaseDatetimeToYYYYMMDDHHMMSS } from "../../../components/function";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import styled from "styled-components";
const StyledSelect = styled.select`
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f3f6f9;
  color: #333;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const StyledOption = styled.option`
  padding: 8px;
  background-color: #fff;
  color: #333;
  font-size: 16px;

  &:hover {
    background-color: #f3f6f9;
  }
`;
export const UnstyledSelectIntroduction = ({
  stateStatus,
  setStateStatus,
  handleRefreshData,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateStatus(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateStatus}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={""}>Lấy tất cả</StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_DATA_PENDING)}
        >
          Đang chờ
        </StyledOption>
        <StyledOption value={parseInt(process.env.REACT_APP_STATUS_DATA_DONE)}>
          Đã hoàn thành
        </StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const SelectOldNewOrder = ({
  setStateSortDate,
  stateSortDate,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateSortDate(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateSortDate}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={"latest"}>Mới nhất</StyledOption>
        <StyledOption value={"oldest"}>Cũ nhất</StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const columns = [
  {
    field: "phone",
    headerName: "Mã khách hàng",
    flex: 1,
  },

  {
    field: "name",
    headerName: "Tên khách hàng",
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },

  {
    field: "status",
    headerName: "Trạng thái",
    flex: 1,
    renderCell: (params) => (
      <>
        {params?.value ===
        parseInt(process.env.REACT_APP_STATUS_DATA_PENDING) ? (
          <Typography color={"#607CFB"}>ĐANG CHỜ</Typography>
        ) : (
          <Typography color={"#607CFB"}>ĐÃ HOÀN THÀNH</Typography>
        )}
      </>
    ),
  },

  {
    field: "preferred_time",
    headerName: "Thời điểm",
    width: 300,
  },

  {
    field: "created_at",
    headerName: "Ngày khởi tạo",
    flex: 1,
    renderCell: (params) => (
      <div>{CaseDatetimeToYYYYMMDDHHMMSS(params?.value)}</div>
    ),
  },
];
export const Export_excel_columns_VN = [
  { header: "SỐ THỨ TỰ", key: "STT", width: 20 },
  { header: "SỐ ĐIỆN THOẠI", key: "phone", width: 40 },
  { header: "TÊN KHÁCH HÀNG", key: "name", width: 40 },
  { header: "EMAIL", key: "email", width: 40 },
  { header: "THỜI GIAN TƯ VẤN", key: "preferred_time", width: 40 },
  { header: "NGÀY KHỞI TẠO", key: "created_at", width: 40 },
];
export const Export_excel_columns_KR = [
  { header: "순서 번호", key: "STT", width: 20 },
  { header: "전화번호", key: "phone", width: 40 },
  { header: "고객 이름", key: "name", width: 40 },
  { header: "이메일", key: "email", width: 40 },
  { header: "상담 시간", key: "preferred_time", width: 40 },
  { header: "생성 날짜", key: "created_at", width: 40 },
];
export const notification_verify = {
  title: "Thao tác thành công",
  message: "Thành công duyệt tài khoản !",
  type: "success",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_unverify = {
  title: "Thao tác thành công",
  message: "Thành công huỷ duyệt tài khoản !",
  type: "default",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_ban = {
  title: "Thao tác thành công!",
  message: "Thành công ngừng hoạt động tài khoản !",
  type: "danger",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
