import { Box, Stack, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";

import { GET_ALL_TRANSACTION_ACCOUNT_UPGRADE } from "../../apis/handleTransaction";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import "./ImageZoom.css"; // Tạo file CSS riêng

import {
  columns,
  SelectOldNewOrder,
  UnstyledSelectIntroduction,
} from "./components/components";

import { CustomToolbar } from "../../components/function";
import {
  CustomNoRowsOverlay,
  SearchComponent,
} from "../../components/components";
const AccountManage = ({ data }) => {
  const [stateAccount, setStateAccount] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [stateListUpgrade, setStateListUpgrade] = useState([]);

  const [stateSort, setStateSort] = useState("asc");
  const [stateSortDate, setStateSortDate] = useState("latest");
  const [stateCodeTransactionSearch, setStateCodeTransactionSearch] =
    useState("");
  const [stateStatus, setStateStatus] = useState("");

  const [selectedRow, setSelectedRow] = useState([]);
  const [stateRole, setStateRole] = useState("");
  const [statePage, setStatePage] = useState(0);
  const [stateLimit, setStateLimit] = useState(20);
  const [stateImgView, setStateImgView] = useState("");
  const [Isloading, setIsloading] = useState({
    loading_verify: false,
    loading_unverify: false,
    loading_ban: false,
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [transformOrigin, setTransformOrigin] = useState("center");
  const [scale, setScale] = useState(1);

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = e.clientX - left; // Vị trí X của chuột
    const y = e.clientY - top; // Vị trí Y của chuột

    const xPercent = (x / width) * 100; // Tỷ lệ X
    const yPercent = (y / height) * 100; // Tỷ lệ Y

    setTransformOrigin(`${xPercent}% ${yPercent}%`);
    setScale(3); // Phóng to ảnh
  };

  const handleMouseLeave = () => {
    setScale(1); // Trở về kích thước ban đầu khi rời chuột
  };
  const DATA_GET_ALL_TRANSACTION = useQuery({
    queryKey: [
      "GET_ALL_TRANSACTION_ACCOUNT_UPGRADE",
      statePage,
      stateStatus,
      stateSortDate,
      stateCodeTransactionSearch,
    ],
    queryFn: async () => {
      const res = await GET_ALL_TRANSACTION_ACCOUNT_UPGRADE({
        page: statePage + 1,
        limit: stateLimit,
        sort: stateSort,
        status: stateStatus,
        transaction_code: stateCodeTransactionSearch,
        date: stateSortDate,
      });
      if (res?.data?.data) {
        let newArray = res?.data?.data?.transactions.map((item, index) => {
          return {
            ...item,
            id: item._id,
          };
        });
        setStateListUpgrade(newArray);
        setRowCount(parseInt(res?.data?.data?.total_transactions));
      }

      return res;
    },
  });

  const onHandleChangePage = (e) => {
    setStatePage(e);
  };
  const onHandleChangePageSize = (e) => {
    setStateLimit(e);
  };
  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((selectedId) =>
      stateAccount
        .map((obj, index) => ({
          ...obj,
          id: obj._id,
        }))
        .find((row) => row.id === selectedId)
    );
    setSelectedRow(selectedRows);

    setSelectionModel(newSelectionModel);
  };
  let Columnsview = columns(setStateImgView);
  return (
    <Box
      m="20px"
      height={"90vh"}
      sx={{
        overflowX: "hidden",

        overflowY: "auto",
      }}
    >
      <Header
        title="Quản lý giao dịch"
        subtitle={
          "Mọi giao dịch sẽ được thể hiện lên bảng bao gồm: nâng cấp và đơn hàng"
        }
      />
      <Box className="row">
        <Box mt={2} className="col-5">
          <Box>
            <SearchComponent
              value={stateCodeTransactionSearch}
              setValue={setStateCodeTransactionSearch}
              placeholder={"Tìm kiếm Mã giao dịch...."}
            ></SearchComponent>
          </Box>
          <Stack
            direction="row"
            width={"100%"}
            // divider={<Divider orientation="vertical" flexItem />}
            justifyContent={"space-between"}
          >
            <Box
              p={2}
              flex={1}
              textAlign={"center"}
              borderRight={"solid 1px #f7f7f7"}
              paddingLeft={"0px"}
            >
              <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
                Chọn lọc
              </Typography>
              <SelectOldNewOrder
                setStatePage={setStatePage}
                setStateSortDate={setStateSortDate}
                stateSortDate={stateSortDate}
              ></SelectOldNewOrder>
            </Box>
            <Box p={2} flex={1} textAlign={"center"}>
              {" "}
              <Typography mb={1} textAlign={"left"} fontSize={"1rem"}>
                Tình Trạng
              </Typography>
              <Typography fontWeight={600}>
                <UnstyledSelectIntroduction
                  stateStatus={stateStatus}
                  setStatePage={setStatePage}
                  setStateStatus={setStateStatus}
                  // handleRefreshData={handleRefreshData}
                ></UnstyledSelectIntroduction>
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box className="col-5">
          <Box className="image-container">
            <img
              alt="Nhấn 1 lần vào ảnh"
              src={stateImgView || ""}
              className="zoom-image"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              style={{
                transformOrigin,
                transform: `scale(${scale})`,
                objectFit: "contain",
                backgroundColor: "green",
                padding: "10px",
                borderRadius: "10px",
                borderWidth: "1px",
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box
        m="40px 0 0 0"
        height="54vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "1.2rem",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "1rem",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={stateListUpgrade || []}
          columns={Columnsview}
          pagination
          page={statePage}
          pageSize={stateLimit}
          rowCount={rowCount}
          paginationMode="server"
          checkboxSelection
          onPageChange={onHandleChangePage}
          onPageSizeChange={onHandleChangePageSize}
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          localeText={{
            toolbarFilters: "Chọn lọc",
            toolbarDensity: "Mật độ",
          }}
          loading={DATA_GET_ALL_TRANSACTION?.isFetching}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
    </Box>
  );
};

export default AccountManage;
