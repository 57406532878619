import NProgress from "nprogress";
import api from "./apis";

export const GET_ALL_DATA = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/accounts/get-forms`, {
      params: {
        page: req.page,
        limit: req.limit,
        name: req.name,
        date: req.date,
        sort: req.sort,
        status: req.status,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_ALL_DATA_BY_CREATE_AT = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/admin/accounts/get-forms-by-created`, {
      params: {
        date: req.date,
        sort: req.sort,
        startTime: req.startTime,
        endTime: req.endTime,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
