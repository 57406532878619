import { CaseDatetimeToYYYYMMDDHHMMSS } from "../../../components/function";

import TouchAppIcon from "@mui/icons-material/TouchApp";
import { Box, FormControl, Typography } from "@mui/material";

import styled from "styled-components";
const StyledSelect = styled.select`
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #f3f6f9;
  color: #333;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const StyledOption = styled.option`
  padding: 8px;
  background-color: #fff;
  color: #333;
  font-size: 16px;

  &:hover {
    background-color: #f3f6f9;
  }
`;

export const columns = (setStateImgView) => {
  return [
    {
      field: "transaction_code",
      headerName: "ID GIAO DỊCH",
      width: 200,
    },
    {
      field: "seller_info",
      headerName: "Thông tin tài khoản",

      width: 350,
      renderCell: (params) => (
        <>
          <Box color={"#ffc5f2"} display={"flex"}>
            <Typography fontSize={"1rem"}>
              id : {params?.value?.phone}
            </Typography>
            &emsp;
            {" - "}
            &emsp;
            <Typography fontSize={"1rem"}>
              Tên : {params?.value?.name}
            </Typography>
          </Box>
        </>
      ),
    },
    {
      field: "code_purchase",
      headerName: "Mã giao dịch đơn hàng",
      width: 200,
      renderCell: (params) => (
        <a href={`/#/chi-tiet-don-hang/${params?.row?.purchase_id}`}>
          {params?.value}
        </a>
      ),
    },

    {
      field: "code_upgrade",
      headerName: "Mã giao dịch nâng cấp",
      width: 200,
      renderCell: (params) => (
        <a href={`/#/chi-tiet-nang-cap/${params?.row?.upgrade_id}`}>
          {params?.value}
        </a>
      ),
    },
    {
      field: "transfer_image",
      headerName: "Hình ảnh hoá đơn",
      width: 200,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setStateImgView(params?.value);
          }}
        >
          {params?.value ? (
            <img
              src={params?.value}
              alt="Hình ảnh"
              style={{ width: "100px", height: "auto", objectFit: "cover" }}
            />
          ) : (
            "No Images"
          )}
        </div>
      ),
    },

    {
      field: "total_price",
      headerName: "Tổng giá tiền",
      width: 200,
      renderCell: (params) => (
        <div>{parseInt(params?.value).toLocaleString("en-us")}</div>
      ),
    },
    {
      field: "status",
      headerName: "Trạng thái",
      width: 200,
      renderCell: (params) => (
        <>
          {params.value ===
          parseInt(process.env.REACT_APP_STATUS_TRANSACTION_PENDING) ? (
            <Typography variant="h4" p={2} bgcolor={"#9b9b61"}>
              ĐANG CHỜ XÁC NHẬN
            </Typography>
          ) : (
            <Typography variant="h4" p={2} bgcolor={"#008000"}>
              ĐÃ ĐƯỢC XÁC NHẬN
            </Typography>
          )}
        </>
      ),
    },
    {
      field: "created_at",
      headerName: "Thời điểm tạo",
      width: 200,
      renderCell: (params) => (
        <div>{CaseDatetimeToYYYYMMDDHHMMSS(params?.value)}</div>
      ),
    },
  ];
};
export const notification_verify = {
  title: "Thao tác thành công",
  message: "Thành công duyệt tài khoản !",
  type: "success",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_unverify = {
  title: "Thao tác thành công",
  message: "Thành công huỷ duyệt tài khoản !",
  type: "default",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const notification_ban = {
  title: "Thao tác thành công!",
  message: "Thành công ngừng hoạt động tài khoản !",
  type: "danger",
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
export const UnstyledSelectIntroduction = ({
  stateStatus,
  setStateStatus,
  handleRefreshData,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateStatus(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateStatus}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={""}>Lấy tất cả giao dịch</StyledOption>
        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_TRANSACTION_PENDING)}
        >
          Đang chờ xác nhận
        </StyledOption>

        <StyledOption
          value={parseInt(process.env.REACT_APP_STATUS_TRANSACTION_DONE)}
        >
          Đã được xác nhận
        </StyledOption>
      </StyledSelect>
    </FormControl>
  );
};

export const SelectOldNewOrder = ({
  setStateSortDate,
  stateSortDate,
  setStatePage,
}) => {
  const handleChange = (event) => {
    setStateSortDate(event.target.value);
    setStatePage(0);
  };
  return (
    <FormControl fullWidth>
      <StyledSelect
        labelId="dropdown-label"
        label="Chọn một mục"
        onChange={handleChange}
        defaultValue={stateSortDate}
        style={{ paddingLeft: "0px" }}
      >
        <StyledOption value={"latest"}>Mới nhất</StyledOption>
        <StyledOption value={"oldest"}>Cũ nhất</StyledOption>
      </StyledSelect>
    </FormControl>
  );
};
