import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import CancelIcon from "@mui/icons-material/Cancel";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Box, Typography } from "@mui/material";
export const CaseDatetimeToDDMMYY = (string) => {
  // Chuỗi datetime ban đầu
  const datetimeStr = string;

  // Tạo đối tượng Date từ chuỗi datetime
  const datetimeObj = new Date(datetimeStr);

  // Chuyển đổi thời gian sang múi giờ Việt Nam (GMT+7)
  const offset = 7 * 60; // GMT+7 in minutes
  const vietnamTime = new Date(datetimeObj.getTime() + offset * 60 * 1000);

  // Lấy các thành phần của datetime
  const day = String(vietnamTime.getUTCDate()).padStart(2, "0");
  const month = String(vietnamTime.getUTCMonth() + 1).padStart(2, "0");
  const year = String(vietnamTime.getUTCFullYear()).slice(-2);
  const hours = String(vietnamTime.getUTCHours()).padStart(2, "0");
  const minutes = String(vietnamTime.getUTCMinutes()).padStart(2, "0");
  const seconds = String(vietnamTime.getUTCSeconds()).padStart(2, "0");

  // Định dạng lại datetime
  const formattedDatetime = `${day}-${month}-${year}`;
  return formattedDatetime;
};

export const CaseDatetimeToYYYYMMDD = (string) => {
  // Chuỗi datetime ban đầu
  const datetimeStr = string;

  // Tạo đối tượng Date từ chuỗi datetime
  const datetimeObj = new Date(datetimeStr);

  // Chuyển đổi thời gian sang múi giờ Việt Nam (GMT+7)
  const offset = 7 * 60; // GMT+7 in minutes
  const vietnamTime = new Date(datetimeObj.getTime() + offset * 60 * 1000);

  // Lấy các thành phần của datetime
  const day = String(vietnamTime.getUTCDate()).padStart(2, "0");
  const month = String(vietnamTime.getUTCMonth() + 1).padStart(2, "0");
  const year = String(vietnamTime.getUTCFullYear()).slice();
  const hours = String(vietnamTime.getUTCHours()).padStart(2, "0");
  const minutes = String(vietnamTime.getUTCMinutes()).padStart(2, "0");
  const seconds = String(vietnamTime.getUTCSeconds()).padStart(2, "0");

  // Định dạng lại datetime
  const formattedDatetime = `${year}-${month}-${day}`;
  return formattedDatetime;
};

export const CaseDatetimeToYYYYMMDDHHMMSS = (string) => {
  // Chuỗi datetime ban đầu
  const datetimeStr = string;

  // Tạo đối tượng Date từ chuỗi datetime
  const datetimeObj = new Date(datetimeStr);

  // Chuyển đổi thời gian sang múi giờ Việt Nam (GMT+7)
  const offset = 7 * 60; // GMT+7 in minutes
  const vietnamTime = new Date(datetimeObj.getTime() + offset * 60 * 1000);

  // Lấy các thành phần của datetime
  const day = String(vietnamTime.getUTCDate()).padStart(2, "0");
  const month = String(vietnamTime.getUTCMonth() + 1).padStart(2, "0");
  const year = String(vietnamTime.getUTCFullYear()).slice();
  const hours = String(vietnamTime.getUTCHours()).padStart(2, "0");
  const minutes = String(vietnamTime.getUTCMinutes()).padStart(2, "0");
  const seconds = String(vietnamTime.getUTCSeconds()).padStart(2, "0");

  // Định dạng lại datetime
  const formattedDatetime = `${year}-${month}-${day} - ${hours}:${minutes}:${seconds}`;
  return formattedDatetime;
};

export const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarFilterButton /> */}
      <GridToolbarDensitySelector />
      <GridToolbarContainer></GridToolbarContainer>
      {/* Bạn có thể thêm các nút hoặc component tùy chỉnh khác tại đây */}
    </GridToolbarContainer>
  );
};
export const CloseModal = ({ handleclose }) => {
  return (
    <>
      {" "}
      <div
        style={{
          position: "absolute",
          objectFit: "cover",
          top: 4,
          right: 4,
          cursor: "pointer",
        }}
        onClick={() => {
          handleclose();
        }}
      >
        <CancelIcon sx={{ color: "GrayText" }} fontSize="large"></CancelIcon>
      </div>
    </>
  );
};

export const HandleExportToExcel = async (
  columns,
  rows,
  name,
  customRowsVerify
) => {
  const today = new Date();

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0, cần +1
  const day = String(today.getDate()).padStart(2, "0");

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`${year} - ${month} - ${day}`);

  // Thiết lập các cột
  worksheet.columns = columns;

  // Thêm style cho tiêu đề của bảng
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true, size: 12, color: { argb: "FFFFFFFF" } };

  columns.forEach((col, index) => {
    const cell = headerRow.getCell(index + 1); // index + 1 vì ExcelJS bắt đầu từ 1
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF0000FF" }, // Màu nền xanh dương
    };
    cell.alignment = { vertical: "middle", horizontal: "center" }; // Căn giữa nội dung
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  // Thêm border cho các ô tiêu đề
  worksheet.getRow(1).eachCell((cell) => {
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  // Thêm dữ liệu vào các hàng và định dạng
  rows.forEach((row, index) => {
    let formattedRow = {
      STT: index + 1,
      ...row,
      created_at: CaseDatetimeToYYYYMMDDHHMMSS(row.created_at),
    };
    if (customRowsVerify) {
      formattedRow = {
        ...formattedRow,
        verify:
          row?.verify === parseInt(process.env.REACT_APP_ACCOUNT_UNVERIFIED)
            ? " Chưa duyệt"
            : row?.verify === parseInt(process.env.REACT_APP_ACCOUNT_VERIFIED)
            ? "Đã duyệt"
            : "Ngưng hoạt động",
      };
    }
    const excelRow = worksheet.addRow(formattedRow);
    // Căn chỉnh các ô
    excelRow.alignment = { vertical: "middle", horizontal: "center" };

    // Thêm border cho các ô
    excelRow.eachCell((cell) => {
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
  });

  // Định dạng chiều cao cho các hàng
  worksheet.eachRow((row, rowNumber) => {
    row.height = 20;
    row.width = 200;
  });

  // Ghi workbook ra file Excel dưới dạng buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Tạo Blob và lưu file Excel
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${name}.xlsx`);
};
